import React, { Fragment, useEffect, useState } from "react";
import { loginRequest } from "../../config/api-config.js";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { Badge, Modal, Image, Statistic, Layout, Alert, Avatar, Drawer } from "antd";
import Navbar from "../../atoms/navbar/index.js";
import "antd/dist/antd.css";
import "./styles.css";

import HeaderFarms from "../../utils/header_farms.js";
import { callMsGraph } from "../../utils/graph";
import { QrReader } from 'react-qr-reader';
import API from "../../services/index.js";

import dumper from './asset/mining-truck-2.png'
import evaluate from './asset/warning.png'
import calendar from './asset/calendar.png'
import helpdesk from './asset/helpdesk.png'
import BrmLogo from './asset/brm-logo.png'
import pending from './asset/pending.png'
import checklist from './asset/permit.png'
import zoom from './asset/zoom.png'
import request from './asset/request.png'
import flight from './asset/flight.png'
import apps from './asset/apps.png'
import car from './asset/car.png'
import oil from './asset/fuel.png'
import more from './asset/more.png'
import capex from './asset/capex.png'
import permitdash from './asset/permit-dash.png'

import { initializeSession, InitializeTicket } from "../../redux/action/index.js";

function Home(props) {

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [pendingList, setPendingList] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [surname, setSurname] = useState(null)
  const [open, setOpen] = useState(false);

  const [scrollDirection, setScrollDirection] = useState('up');
  let lastScrollTop = 0;

  let navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
    lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
  };

  function getAllPending(accessToken, email) {

    API.workflowPending(HeaderFarms(accessToken), email).then(res => {
      let DTO = []

      res.data.forEach(element => {
        if (element.approval_id && (element.approval_id.status === 'Waiting Approval') && element.status === false) {
          DTO.push(element)
        }
      });

      setPendingList(DTO.length)

    })
      .catch((err) => {

        console.log(err.response.status)

        if (err.response.status === 400) {
          navigate('/completed-user')
        }

      });
  }

  const Verification = async (token) => {
    try {
      const res = await API.getUserCredential(HeaderFarms(token), { access_token: token });

      // number phone exist but not already activation
      if (res.phone_number && !res.phone_number_activation) {
        navigate('/verification/' + res.phone_number + '/' + token);
      }

      if (!res.phone_number) {
        navigate('/verification/change/' + token);
      }

      // set if the user is registered on Farms
      props.setInitializeSession(res);

      localStorage.setItem('session_signin', JSON.stringify(res));
      return res;
    } catch (err) {
      console.error(err, "Verification");
      // You might want to handle the error or rethrow it depending on your use case.
      throw err;
    }
  };

  async function RequestToken() {

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (responseToken) => {

        callMsGraph(responseToken.accessToken).then(async (response) => {

          // get All pending approval
          getAllPending(responseToken.accessToken, response.mail)
          setSurname(response)

          // if complete data user, next verification Whatsapp
          // Verification WA 
          let status = await Verification(responseToken.accessToken)

          if (!status) {
            //if data farms user not yet, data will get from 365
            props.setInitializeSession(response);
          }

        });
      })
      .catch((e) => {
        console.error(e)
      });
  }

  function HistoryTicket() {
    navigate(`/help/history-ticket`);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if (inProgress === InteractionStatus.None && accounts.length === 0) {
      instance.loginRedirect(loginRequest);
    }

    if (isAuthenticated) {
      // Get Total List Pending
      RequestToken()
    }

    // Patent Redirect for domain spesific
    const currentURL = window.location.href;
    const url = new URL(currentURL);

    switch (url.hostname) {
      case "fuel.citrapalu.net":
      case "backdaterf.citrapalu.net":
      case "backdatedr.citrapalu.net":
        return navigate('/fms/menu');
      default:
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, isAuthenticated]);


  return (
    <Fragment>
      <Navbar
        title={<Image preview={false} src={BrmLogo} width={48} />}
        back={false}
        subtitle=""
      ></Navbar>
      <div className="container-fluid home_back" >
        <div className="container p-1" style={{ alignSelf: "center" }}>
          <div className="row mb-2 mt-4">
            <div className="col-md-6 col-sm-6 d-flex w-100">
              {surname && (
                <>
                  <Avatar
                    style={{ marginRight: '16px', marginTop: 7 }}
                    // icon={<UserOutlined />}
                    src={`https://avatar.iran.liara.run/username?username=${surname.displayName}`}
                    size="large"
                  // shape="square"
                  />
                  <Statistic
                    title={(function () {
                      const sessionData = localStorage.getItem("session_signin");
                      try {
                        return sessionData ? JSON.parse(sessionData).company.fullname : "-";
                      } catch {
                        return "-";
                      }
                    })()}
                    value={surname.displayName}
                  />

                </>
              )}
            </div>
          </div>
        </div>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12">
              <Alert className="mt-2" message={"Production"} type="success" showIcon />
            </div>
          </div>
        </div>
        <div
          className="container p-4 white-back-home mt-2"
          style={{ alignSelf: "center", height: "70vh" }}
        >
          <div className="row">
            <div className="col-md-1 col-sm-1 p-0 w-25">
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <Badge count={pendingList} size="default" showZero>
                      <div onClick={() => {
                        navigate('/wf/pending')
                      }} className="item-menu">
                        <img className="logo-menu" src={pending} alt=""></img>
                      </div>
                    </Badge>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Pending Approval
                    </div>
                  </div>
                </div>
              </div>
            </div>            
            <div className="col-md-1 col-sm-1 p-0 w-25">
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      HistoryTicket()
                    }
                    } className="item-menu">
                      <img className="logo-menu" style={{ margin: 7 }} src={helpdesk} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      IT Help Request
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25">
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center position-relative">
                    <div
                      onClick={() => {
                        navigate('/zoom')
                      }}
                      className="item-menu"
                      style={{ position: 'relative' }}
                    >
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={zoom} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Zoom Request 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25">
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/driver')
                    }} className="item-menu">
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={car} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Operational Car
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/ts/history')
                    }} className="item-menu">
                      <img className="logo-menu" src={calendar} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Time Sheet
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/ta/history')
                    }
                    } className="item-menu">
                      <img className="logo-menu" src={flight} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Travel Authority
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/sr/history')
                    }
                    } className="item-menu">
                      <img className="logo-menu" src={request} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Service Request
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/fms/menu')
                    }}
                      className="item-menu">
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={oil} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Fuel System
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/wp/history')
                    }} className="item-menu">
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={checklist} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Working Permit
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/hias/create')
                    }}
                      className="item-menu">
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={evaluate} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      HIAS
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      navigate('/capex/history')
                    }} className="item-menu">
                      <img style={{ margin: 11, maxWidth: 100 }} className="logo-menu" src={capex} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Capex Request
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
              <div className="group-menu">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div onClick={() => {
                      showDrawer(true)
                    }} className="item-menu">
                      <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={more} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="title-item-menu">
                      Other Apps
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal title="QR Camera" visible={isModalVisible} footer={null} onCancel={() => handleCancel()}>
              {
                isModalVisible && (
                  <QrReader
                    onResult={(result, error) => {
                      if (!!result) {
                        window.location = result;
                      }

                    }}
                    constraints={{
                      facingMode: "environment"
                    }}
                    style={{ width: '100%' }}

                  />
                )
              }
            </Modal>

          </div>
        </div>
        {/* d-md-none d-lg-none d-xl-none  */}
        <nav className={`navbar navbar-light bg-light border-top navbar-expand fixed-bottom ${scrollDirection === 'down' ? 'hide' : ''}`}>
          <ul className="navbar-nav nav-justified w-100">
            <li className="nav-item">
              <a onClick={() => {
                navigate('/home')
                onClose()
              }} className="nav-link text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#027D4E" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
                </svg>
                <div style={{ color: "#027D4E" }}>Home</div>
              </a>
            </li>
            <li className="nav-item">
              <a onClick={() => {
                HistoryTicket()
              }
              } className="nav-link text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#027D4E" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                  <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                </svg>
                <div style={{ color: "#027D4E" }}>Request</div>
              </a>
            </li>
            <li className="nav-item">
              <a onClick={showModal} className="nav-link text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#027D4E" class="bi bi-qr-code-scan" viewBox="0 0 16 16">
                  <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z" />
                  <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z" />
                  <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z" />
                  <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z" />
                  <path d="M12 9h2V8h-2z" />
                </svg>
                <div style={{ color: "#027D4E" }}>Scan QR</div>
              </a>
            </li>

            <li className="nav-item">
              <a onClick={() => {
                navigate('/wf/pending')
              }} className="nav-link text-center">
                <Badge style={{ fontSize: 8, padding: 0 }} count={pendingList} size="small" showZero>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#027D4E" class="bi bi-inbox-fill" viewBox="0 0 16 16">
                    <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374z" />
                  </svg>
                </Badge>
                <div style={{ color: "#027D4E" }}>Inbox</div>
              </a>
            </li>
          </ul>
        </nav>

        <Layout.Footer className="footer_background" style={{ textAlign: 'center' }}>
          © {new Date().getFullYear()} Information Technology <a style={{
            opacity: 0,
            position: "absolute",
          }} href="https://farms-admin.brmapps.com" target="_blank" rel="noopener noreferrer">
            FARMS Admin
          </a>
        </Layout.Footer>
        <Drawer
          title="Other Apps"
          placement={"bottom"}
          width={500}
          onClose={onClose}
          open={open}
          height="80vh"
        >
          <div
            className="container p-1 white-back-home"
            style={{ alignSelf: "center", height: "90vh" }}
          >
            <div className="row">
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/under-construction')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={permitdash} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        Production Report
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/project-monitoring')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={permitdash} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        Project Monitoring
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/wp-dashboard')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={permitdash} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        Work Permit Dashboard
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/farms-activity-dashboard')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={permitdash} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        FARMS Activity
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/application-list')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={apps} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        App List
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 p-0 w-25" style={{ marginTop: 15 }}>
                <div className="group-menu">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <div onClick={() => {
                        navigate('/under-construction')
                      }} className="item-menu">
                        <img style={{ margin: 7, maxWidth: 100 }} className="logo-menu" src={dumper} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="title-item-menu">
                        Mining Activity
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div >


    </Fragment >
  );
}

const reduxState = (state) => ({
  verification: state.verification,
  session: state.session
});

const reduxDispatch = (dispatch) => ({
  setInitializeTicket: (navigate, data) =>
    dispatch(InitializeTicket(navigate, data)),
  setInitializeSession: (data) =>
    dispatch(initializeSession(data)),
});

export default connect(reduxState, reduxDispatch)(Home);