import React, { useEffect, useState } from "react";

import {
  Layout,
  Collapse,
  Form,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Radio,
  InputNumber,
  Input,
  Descriptions,
  Timeline,
  Alert,
  Comment,
  Avatar,
  Affix,
  Button,
  Image,
} from "antd";

import Navbar from "../../../atoms/navbar";

import { loginRequest } from "../../../config/api-config";
import HeaderFarms from "../../../utils/header_farms";
import API from "../../../services";

import { useMsal } from "@azure/msal-react";
import { Block, Confirm, Report } from "notiflix";
import { useNavigate, useParams } from "react-router";
import { CheckOutlined, SettingOutlined } from "@ant-design/icons";
import { QRCodeSVG } from "qrcode.react";
import moment from "moment";

function ViewRequestDeliveryFuel() {
  const { instance, accounts } = useMsal();
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const [statusColor, setStatusColor] = useState({
    background: null,
    color: null,
  });
  const [RequestDeliveryDetail, setRequestDeliveryDetail] = useState(false);
  const [user, setUser] = useState(null);
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  let { id } = useParams();

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        const token = responseToken.accessToken;
        getDetailDeliveryRequest(token);
      })
      .catch((e) => {});
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), {
        access_token: token,
      });
      setUser(user);
    } catch (error) {
      console.error(error);
    }
  }

  const styleStatus = (res) => {
    let status = res.approval_process.status;

    if (status === "Waiting Approval") {
      setStatusColor({
        background: "#FF9900",
        color: "#FFFFFF",
      });
    } else if (status === "Approved") {
      setStatusColor({
        background: "#1890FF",
        color: "#FFFFFF",
      });
    } else {
      setStatusColor({
        background: "#F5222D",
        color: "#FFFFFF",
      });
    }
  };

  const getDetailDeliveryRequest = async (token) => {
    try {
      const res = await API.ViewDeliveryRequestFuel(HeaderFarms(token), id);

      Block.remove(".ant-collapse");

      res.data.date = moment(res.data.date);
      res.data.time = moment(res.data.time);

      form.setFieldsValue({
        issued_period: [
          moment(res.data.issued_period_date_from),
          moment(res.data.issued_period_date_to),
        ],
        ...res.data,
      });

      form.setFieldsValue(res.data);

      setRequestDeliveryDetail(res.data);
      styleStatus(res.data);
      // // only one call
      user || getUser(token);
    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Fuel Request not found", "Okay");
      Block.remove(".ant-collapse");
    }
  };

  const submitApprove = () => {
    Confirm.show(
      "Important Confirmation",
      "Are you sure ?.",
      "Yes",
      "No",
      () => {
        approved();
      }
    );
  };

  const approved = () => {
    // check approver or not
    let getDetailProcess = RequestDeliveryDetail.approval_process.detail.filter(
      (item) => item.approved_by._id === user.id && item.status === false
    )[0]?._id;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    if (!getDetailProcess) {
      getDetailProcess = RequestDeliveryDetail.approval_process.detail[0]._id;
      request.deputy = true;
    } else {
      request.deputy = false;
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        // Note : if deputy true, will get parameter deputy from nik session login farms.
        API.workflowApproved(HeaderFarms(response.accessToken), {
          approval_id_list: getDetailProcess,
          deputy: request.deputy, // Use the deputy value from the request object
        })
          .then((res) => {
            Report.success(
              `Approved Success`,
              `Berhasil di setujui, Terima Kasih`,
              "Okay",
              function () {
                return navigate("/fms/menu");
              }
            );
          })
          .catch((err) => {
            console.error(err);
            Report.warning(
              "Oops.. something wrong",
              "Sorry, this application is experiencing problems",
              "Okay"
            );
          });
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  const handleAdjustment = () => {
    return navigate("/fms/delivery/adjustment/" + id);
  };

  useEffect(() => {
    if (accounts.length > 0) {
      RequestToken();
    }
  }, []);

  return (
    <>
      <Navbar
        title="Create"
        back={true}
        subtitle="Fuel System"
        navigate="/fms/menu"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="fuel_delivery"
          initialValues={{
            remember: true,
          }}
          encType="multipart/form-data"
          onFinish={null}
          onFinishFailed={null}
          autoComplete="off"
          size="middle"
          layout="vertical"
        >
          <div className="container-fluid p-2" style={{ alignSelf: "center" }}>
            <div className="row">
              <div className="col-md-12 mb-2">
                <Collapse defaultActiveKey={["1"]}>
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Approval Process</b>}
                    key="1"
                  >
                    {RequestDeliveryDetail ? (
                      <>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            {RequestDeliveryDetail && (
                              <Descriptions
                                size="small"
                                bordered
                                title="Information"
                                className="mb-4"
                              >
                                <Descriptions.Item label="Form ID">
                                  {RequestDeliveryDetail.record_id}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  style={{
                                    background: statusColor.background,
                                    color: statusColor.color,
                                  }}
                                  label="Status"
                                >
                                  {
                                    RequestDeliveryDetail.approval_process
                                      .status
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Created at">
                                  {moment(
                                    RequestDeliveryDetail.created_at
                                  ).format("LLL")}
                                </Descriptions.Item>
                              </Descriptions>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <label style={{ fontSize: 15, fontWeight: "bold" }}>
                              Approval Status
                            </label>
                            <Timeline style={{ marginTop: 30 }}>
                              {RequestDeliveryDetail.approval_process.detail.map(
                                (item) => {
                                  return (
                                    <Timeline.Item key={item._id}>
                                      {item.deputy_approver
                                        ? `Deputy (${item.deputy_approver})`
                                        : item.approved_by.fullname}
                                      <Alert
                                        message={
                                          item.status
                                            ? `Approved - ${moment(
                                                item.approved_at
                                              ).format("YYYY-MM-DD HH:mm")}`
                                            : `Waiting Approval`
                                        }
                                        className="mt-1 p-1"
                                        type={
                                          RequestDeliveryDetail.approval_process.detail.find(
                                            (a) => a.status === false
                                          )
                                            ? "warning"
                                            : "success"
                                        }
                                        showIcon
                                      />
                                    </Timeline.Item>
                                  );
                                }
                              )}
                            </Timeline>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Panel>
                </Collapse>
              </div>
              {RequestDeliveryDetail &&
                RequestDeliveryDetail.approval_process.approval_key && (
                  <>
                    <div className="col-md-12">
                      <Collapse defaultActiveKey={["1"]}>
                        <Panel header="Approved Barcode" key="1">
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <QRCodeSVG
                                size={170}
                                style={{
                                  border: 1,
                                  borderRadius: 2,
                                  borderStyle: "solid",
                                  padding: 10,
                                }}
                                value={`${window.location.origin}/wf/approved/${RequestDeliveryDetail.approval_process.approval_key}`}
                              />
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </>
                )}
              <div className="col-md-12">
                {RequestDeliveryDetail &&
                  RequestDeliveryDetail.approval_process.comments.length >
                    0 && (
                    <Collapse defaultActiveKey={["1"]}>
                      <Panel header="Comments" key="1">
                        {RequestDeliveryDetail &&
                          RequestDeliveryDetail.comments.map((data) => {
                            return (
                              <Comment
                                key={data._id}
                                author={<p>{data.uid.fullname}</p>}
                                avatar={
                                  <Avatar
                                    src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear"
                                    alt="Han Solo"
                                  />
                                }
                                content={<p>{data.text_plain}</p>}
                              />
                            );
                          })}
                      </Panel>
                    </Collapse>
                  )}
              </div>
              <div className="col-md-12">
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Informasi Penyerahan</b>}
                    key="2"
                  >
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please input date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ pointerEvents: "none" }}
                            showTime
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label="Time"
                          name="time"
                          rules={[
                            {
                              required: true,
                              message: "Please input time",
                            },
                          ]}
                        >
                          <TimePicker
                            style={{ pointerEvents: "none" }}
                            showNow={false}
                            format="HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label="Shift"
                          name="shift"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Fuel Truck"
                          name="fuel_truck"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Tangki Penyimpanan"
                          name="location_storage"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 11 }}
                        xl={{ span: 11 }}
                      >
                        <Form.Item
                          label="Nama Supir / Operator"
                          name="operator_id"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jenis Bahan Bakar"
                          name="fuel_type_delivery"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Radio.Group defaultValue="Bio Solar B35">
                            <Radio value="Bio Solar B35">Bio Solar B35</Radio>
                            <Radio value="Benzine">Bensin</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Quantity yang diminta"
                          name="quantity_request"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Pemeriksaan</b>}
                    key="2"
                  >
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Kondisi Tangki"
                          name="tank_kondition"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Radio.Group defaultValue={"Filled"}>
                            <Radio value="Blank">Kosong</Radio>
                            <Radio value="Filled">Terisi</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          label="Periode Penyaluran"
                          name="issued_period"
                          rules={[
                            {
                              required: true,
                              message: "Please input date",
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <RangePicker format="YYYY-MM-DD HH:mm" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Penyaluran Sebelumnya"
                          name="issued_period_before"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        <Form.Item
                          label="Sisa Minyak Dalam Tangki"
                          name="remaining_fuel_period"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      {RequestDeliveryDetail && (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 6 }}
                          xl={{ span: 6 }}
                        >
                          <Form.Item
                            label={"Fueloss Desc"}
                            name="fueloss_description"
                            rules={[
                              {
                                required: true,
                                message: "Wajib Isi",
                              },
                            ]}
                          >
                            <TextArea rows={2} />
                          </Form.Item>
                          <Form.Item
                            name="fueloss"
                            hidden
                            rules={[
                              {
                                required: true,
                                message: "Wajib Isi",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Sounding Awal"
                          name="sounding_before"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber readOnly type={"number"} step={0.1} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                        <Form.Item
                          label="Sounding Akhir"
                          name="sounding_after"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber readOnly type={"number"} step={0.1} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Angka Flowmeter"
                          name="flowmeter_total"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Total Setelah Pengisian"
                          name="issued_total"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="col-md-12">
              {RequestDeliveryDetail && (
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Attachment Flowmeter</b>}
                    key="2"
                  >
                    <div className="row">
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Flow Meter
                        </label>
                        {RequestDeliveryDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-flowmeter.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDeliveryDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/201"}
                          />
                        )}
                      </div>
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Operator
                        </label>
                        {RequestDeliveryDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-operator.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDeliveryDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/200"}
                          />
                        )}
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              )}
            </div>
          </div>
          {RequestDeliveryDetail && user && (
            <div className="row mt-4">
              <div className="col-md-12">
                <Affix offsetBottom={10}>
                  <div className="d-flex justify-content-center">
                    {RequestDeliveryDetail.approval_process.status ===
                      "Waiting Approval" &&
                      RequestDeliveryDetail.approval_process.form_id.form_setting.approved_by.find(
                        (pic) => pic === user.id
                      ) && (
                        <Form.Item className="text-center m-3">
                          <Button
                            type="primary"
                            onClick={submitApprove}
                            size="middle"
                            htmlType="submit"
                            icon={<CheckOutlined />}
                          >
                            Approved
                          </Button>
                        </Form.Item>
                      )}
                    <Form.Item className="text-center m-3">
                      <Button
                        onClick={handleAdjustment}
                        type="primary"
                        size="middle"
                        icon={<SettingOutlined />}
                        disabled={
                          RequestDeliveryDetail.approval_process.form_id.form_setting.approved_by.find(
                            (pic) => pic === user.id
                          )
                            ? false
                            : true
                        }
                      >
                        Adjustment
                      </Button>
                    </Form.Item>
                  </div>
                </Affix>
              </div>
            </div>
          )}
        </Form>
      </div>
      <Layout.Footer style={{ textAlign: "center", background: "#F9F9F9" }}>
        ©2024 Information Technology
      </Layout.Footer>
    </>
  );
}

export default ViewRequestDeliveryFuel;
