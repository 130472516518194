import Get from "./axios/get";
import Put from "./axios/put";
import Post from "./axios/post";
import {
  farms_backend,
  fuel_service,
  OsTicket,
  whatsappServices,
  booking_driver,
  capex_request,
  approval_request,
} from "../config/api-config";

// POST
const ReplyFromUser = (header, data) =>
  Post(`${OsTicket}/api/http.php/tickets/reply-user.json`, header, data);
const CreateTicket = (header, data) =>
  Post(`${OsTicket}/api/http.php/tickets.json`, header, data);

// GET
const DetailTicket = (header, email) =>
  Get(
    `${OsTicket}/api/http.php/tickets/clientTickets?clientUserMail=${email}`,
    header
  );
const TicketInfo = (header, id) =>
  Get(`${OsTicket}/api/http.php/tickets/ticketInfo?ticketNumber=${id}`, header);
const getTopics = (header, _) =>
  Get(`${OsTicket}/api/http.php/tickets/getTopics`, header);

// Approval System
const ApprovalCancel = (header, data) =>
  Post(`${approval_request.endpoint}/api/cancel`, header, data);
const ApprovalReject = (header, data) =>
  Post(`${approval_request.endpoint}/api/reject`, header, data);
const ApprovalApproved = (header, data) =>
  Post(`${approval_request.endpoint}/api/approved`, header, data);

//WA Notificationn
const sendPersonalWa = (data) =>
  Post(
    `${whatsappServices.waendpoint}/api/wa/pc`,
    whatsappServices.api_key,
    data
  );
const sendGroupWa = (data) =>
  Post(
    `${whatsappServices.waendpoint}/api/wa/gr`,
    whatsappServices.api_key,
    data
  );

//Capex Request
const CreateCapex = (header, data) =>
  Post(`${capex_request.endpoint}/api/`, header, data);
const UsersBranchCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/usersbranch`, header);
const HistoryCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/history`, header);
const ViewCapex = (header, data) =>
  Get(`${capex_request.endpoint}/api/${data}`, header);
const EditCapex = (header, data) =>
  Put(`${capex_request.endpoint}/api/`, header, data);

const DepartementCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/departments`, header);
const AssetGroupCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/assetGroup`, header);
const ActivityCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/activity`, header);
const ProjectsCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/projects`, header);
const VendorCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/vendor`, header);
const AssetCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/assets`, header);
const AreaCapex = (header, _) =>
  Get(`${capex_request.endpoint}/api/ep/area`, header);
// Fuel
const HistoryRequestFuel = (header, data) =>
  Get(
    `${fuel_service.endpoint}/api/requests?startDate=${data.startDate}&endDate=${data.endDate}`,
    header,
    data
  );
const CreateRequestFuel = (header, data) =>
  Post(`${fuel_service.endpoint}/api/request`, header, data, 600000);
const AdjustmentRequestFuel = (header, data) =>
  Post(`${fuel_service.endpoint}/api/adjustment`, header, data);
const ViewRequestFuel = (header, data) =>
  Get(`${fuel_service.endpoint}/api/request/${data}`, header);

const HistoryRequestDelivery = (header, data) =>
  Get(
    `${fuel_service.endpoint}/api/delivery?startDate=${data.startDate}&endDate=${data.endDate}`,
    header,
    data
  );
const DeliveryLatest = (header, data) =>
  Get(`${fuel_service.endpoint}/api/delivery/latest?ft=${data}`, header, data);
const ViewDeliveryRequestFuel = (header, data) =>
  Get(`${fuel_service.endpoint}/api/delivery/${data}`, header);
const CreateRequestDelivery = (header, data) =>
  Post(`${fuel_service.endpoint}/api/delivery`, header, data, 600000);
const AdjustmentDeliveryRequestFuel = (header, data) =>
  Post(`${fuel_service.endpoint}/api/delivery/adjustment`, header, data);

const Organization = (header, data) =>
  Get(`${fuel_service.endpoint}/api/request/organization`, header, data);
const Equipments = (header, data) =>
  Get(`${fuel_service.endpoint}/api/equipment`, header, data);

const CreateOperator = (header, data) =>
  Post(`${fuel_service.endpoint}/api/operator/register`, header, data);
const Operators = (header, data) =>
  Get(`${fuel_service.endpoint}/api/operator`, header, data);
const ResetPasswordOperator = (header, data) =>
  Post(`${fuel_service.endpoint}/api/operator/reset`, header, data);

const JobLocation = (header, data) =>
  Get(`${fuel_service.endpoint}/api/job-locations`, header, data);

const EquipmentActivity = (header, data) =>
  Get(`${fuel_service.endpoint}/api/equipment-activities`, header, data);

// TimeSheet
const historyTimeSheet = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/ts/history?email=${data}`, header);
const createTimeSheet = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/ts/create`, header, data);
const getUserCredential = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/signin`, header, data);
const viewTS = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/ts/view?_id=${data}`, header);
const PrintTSRange = (header, _) =>
  Get(
    `${farms_backend.endpoint}/api/dashboard/printAll?dateFrom=2022-09-17&dateTo=2022-10-16`,
    header
  );
const getLastOutstanding = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/ts/get-last-outstanding`,
    header,
    data
  );
const getListHoliday = (header, data) =>
  Get(`${farms_backend.endpoint}/api/timesheet/get-holiday`, header, data);

// Billing Telkomsel
const ViewBillingTelkomsel = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/biling/telkomsel/view?id=${data}`,
    header
  );

// Service Request
const newServiceRequest = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/sr`, header, data);
const getUsersSR = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/sr/users`, header);
const getApplications = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/sr/applications`, header);
const HistorySR = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/sr/history?email=${data}`, header);
const viewSR = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/sr?_id=${data}`, header);

// Travel Authority
const newTravelAuthority = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/ta/create`, header, data);
const HistoryTA = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/ta/history`, header);
const viewTA = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/ta/view?record_id=${data}`, header);
const PrintTA = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/ta/print?record_id=${data}`,
    header
  );
const getUsersTA = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/sr/users`, header);
const getPicTA = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/ta/pic`, header);

// HIAS
const CreateHias = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/hias`, header, data);

// Working Permit
const getWorkingItems = (header, _) =>
  Get(`${farms_backend.endpoint}/api/access/wp/items`, header);
const historyWorkingPermit = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/wp/history?email=${data}&startDate=${data.startDate}&endDate=${data.endDate}`,
    header
  );
const historyWorkingPermitMonitor = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/wp/history/monitor?startDate=${data.startDate}&endDate=${data.endDate}`,
    header
  );
const createWorkingPermit = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/wp/create`, header, data);

const viewWorkingPermit = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/wp/view?id_record=${data}`,
    header,
    data
  );
const WorkingWorkEnd = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/wp/end`, header, data);
const WorkingWorkEdit = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/wp/edit`, header, data);
const ResponsibleArea = (header, data) =>
  Get(`${farms_backend.endpoint}/api/access/wp/responsible-area`, header, data);
// const APDItems = (header, _) => Get(`${farms_backend.endpoint}/api/access/wp/apd`, header)

// Valication Permit
const viewValidation = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/vwp/view?id_record=${data}`,
    header,
    data
  );
const addNotesValidation = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/vwp/notes`, header, data);
const createValidation = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/vwp/create`, header, data);

// WorkFlow
const workflowApprovedDetails = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/approval/approvedof?approval_key=${data}`,
    header,
    data
  );
const workflowPending = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/access/approval/list/pending?email=${data}`,
    header,
    data
  );
const workflowApproved = (header, data) =>
  Post(
    `${farms_backend.endpoint}/api/access/approval/list/approve`,
    header,
    data
  );
const workflowReject = (header, data) =>
  Post(
    `${farms_backend.endpoint}/api/access/approval/list/reject`,
    header,
    data
  );
const workflowCancel = (header, data) =>
  Post(
    `${farms_backend.endpoint}/api/access/approval/list/cancel`,
    header,
    data
  );

// Get data from api HCM Recruitment
const getUsers = (header, _) =>
  Get(
    `${farms_backend.endpoint}/api/integration/hcm-recruitment/supervisor`,
    header
  );
const getDepartment = (header, _) =>
  Get(
    `${farms_backend.endpoint}/api/integration/hcm-recruitment/departments`,
    header
  );
const getLevel = (header, _) =>
  Get(
    `${farms_backend.endpoint}/api/integration/hcm-recruitment/level`,
    header
  );

// WA Verification
const waVerification = (header, data) =>
  Post(`${farms_backend.endpoint}/api/access/auth/verification`, header, data);
const completeUserData = (header, data) =>
  Post(
    `${farms_backend.endpoint}/api/access/auth/completed-user`,
    header,
    data
  );

// Stock
const Stock = (header, _) =>
  Get(`${farms_backend.endpoint}/api/integration/stock`, header);

// Booking Driver
const CreateBookingDriver = (header, data) =>
  Post(`${booking_driver.endpoint}/api/request`, header, data);
const UpdateBookingDriver = (header, data) =>
  Put(`${booking_driver.endpoint}/api/request`, header, data);
const ListBookingDriverByPlateNumber = (header, data) =>
  Get(`${booking_driver.endpoint}/api/request?plate_number=${data}`, header);
const ListBookingDriverByIdRecord = (header, data) =>
  Get(`${booking_driver.endpoint}/api/request?id_record=${data}`, header);
const getJobPositionTalentaByid = (header) =>
  Get(`${booking_driver.endpoint}/api/talenta/employee/job-position`, header);

// Get static Data
const Cities = (header, _) =>
  Get(`${farms_backend.endpoint}/api/integration/city`, header);
const Airports = (header, _) =>
  Get(`${farms_backend.endpoint}/api/integration/airports`, header);
const Airlines = (header, _) =>
  Get(`${farms_backend.endpoint}/api/integration/airlines`, header);

// Get Data Talenta
const getEmployeeTalentaByEmployeeId = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/talenta/employee?employee_id=${data}`,
    header
  );
const getEmployeeTalentaByBranch = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/talenta/employee/branch?branch_id=${data}`,
    header
  );
const getEmployeeTalentaByid = (header, data) =>
  Get(
    `${farms_backend.endpoint}/api/talenta/employee/detail?id=${data}`,
    header
  );

const API = {
  //Approval System
  ApprovalCancel,
  ApprovalReject,
  ApprovalApproved,

  // os ticket
  CreateTicket,
  ReplyFromUser,
  DetailTicket,
  TicketInfo,
  getTopics,

  // Capex
  CreateCapex,
  UsersBranchCapex,
  HistoryCapex,
  ViewCapex,
  EditCapex,

  DepartementCapex,
  AssetGroupCapex,
  ActivityCapex,
  ProjectsCapex,
  VendorCapex,
  AssetCapex,
  AreaCapex,

  // wa notification
  sendGroupWa,
  sendPersonalWa,

  // timesheet
  createTimeSheet,
  historyTimeSheet,
  viewTS,
  getUserCredential,
  PrintTSRange,
  getLastOutstanding,
  getEmployeeTalentaByid,
  getListHoliday,

  // Billing Telkomsel
  ViewBillingTelkomsel,

  // Service Request
  getUsersSR,
  getApplications,
  newServiceRequest,
  HistorySR,
  viewSR,

  // Travel Authority
  getUsersTA,
  newTravelAuthority,
  HistoryTA,
  viewTA,
  getPicTA,
  PrintTA,

  // HIAS
  CreateHias,

  // workflow
  workflowPending,
  workflowApproved,
  workflowReject,
  workflowCancel,
  workflowApprovedDetails,

  //WA Verification
  waVerification,
  completeUserData,

  // Stock
  Stock,

  //Static Data
  Airlines,
  Airports,
  Cities,

  //Get Data Master
  getDepartment,
  getUsers,
  getLevel,

  // Working Permit
  getWorkingItems,
  createWorkingPermit,
  historyWorkingPermit,
  viewWorkingPermit,
  WorkingWorkEnd,
  createValidation,
  addNotesValidation,
  viewValidation,
  WorkingWorkEdit,
  historyWorkingPermitMonitor,
  ResponsibleArea,

  // Fuel
  Operators,
  Equipments,
  JobLocation,
  Organization,
  DeliveryLatest,
  CreateOperator,
  ViewRequestFuel,
  CreateRequestFuel,
  HistoryRequestFuel,
  CreateRequestDelivery,
  HistoryRequestDelivery,
  ViewDeliveryRequestFuel,
  ResetPasswordOperator,
  AdjustmentRequestFuel,
  EquipmentActivity,
  AdjustmentDeliveryRequestFuel,

  //Booking Driver
  CreateBookingDriver,
  UpdateBookingDriver,
  ListBookingDriverByIdRecord,
  ListBookingDriverByPlateNumber,

  //Talenta
  getEmployeeTalentaByEmployeeId,
  getEmployeeTalentaByBranch,
  getJobPositionTalentaByid,
};

export default API;
