import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

// AntDesign
import {
  Layout,
  Alert,
  Collapse,
  Form,
  AutoComplete,
  Input,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  message,
  notification,
  Modal,
  Select,
  DatePicker,
  Divider,
  InputNumber,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import InputMask from "react-input-mask";

import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import numeral from "numeral";

import Navbar from "../../../atoms/navbar";

import "./styles.css";
import API from "../../../services";
import HeaderFarms from "../../../utils/header_farms";
import { loginRequest } from "../../../config/api-config";

import dataFlightsBenefits from "../data/flights_benefits.json";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Block, Confirm, Loading, Notify, Report } from "notiflix";
import moment from "moment";

function CreateTravelAuthority(props) {
  const [form] = Form.useForm();
  const [form_admin] = Form.useForm();

  const { Panel } = Collapse;
  const { TextArea } = Input;
  const { Option } = AutoComplete;
  const { RangePicker } = DatePicker;

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const [selectedTime, setSelectedTime] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [citiesData, setCitiesData] = useState(null);
  const [airlines, setAirLines] = useState(null);
  const [airport, setAirport] = useState(null);

  //auto complete username
  const [options, setOptions] = useState([]);
  const [oldUser, setOldUser] = useState([]);
  const [nonEmployee, setNonEmployee] = useState([]);

  const [employeeBranch, setEmployeeBranch] = useState(0);

  // set Approver
  const [approverList, setApproverList] = useState([]);

  // upload attachment
  const [fileList, setFileList] = useState({});

  const [rangeDateAccomodation, setRangeDateAccomodation] = useState({
    accomodation_date_in: null,
    accomodation_date_out: null,
  });

  const [departureDate, setDeptatureDate] = useState({
    departure_date_start: null,
    accomodation_date_end: null,
  });

  const handleDateChangeAccomodation = (dates) => {
    const [start, end] = dates;
    const accomodation_date_in = start.format("YYYY-MM-DD");
    const accomodation_date_out = end.format("YYYY-MM-DD");
    setRangeDateAccomodation({
      ...rangeDateAccomodation,
      accomodation_date_in,
      accomodation_date_out,
    });
  };

  const handleDateChangeDeptature = (dates) => {
    const [start, end] = dates;

    const departure_date_start = start.format("YYYY-MM-DD");
    const departure_date_end = end.format("YYYY-MM-DD");
    setDeptatureDate({
      ...departureDate,
      departure_date_start,
      departure_date_end,
    });
  };

  // settings panel
  const settingsPanels = {
    information_not_employee: false,
    reason_for_travel: true,
    reason_for_travel_other_hide: true,
    reason_for_travel_other_disabled: true,
    expense_type_disabled: true,
    objective_disabled: true,
    fullname: null,
    show_ticket: false,
    add_travel_cost_disabled: false,
    show_accomodation: false,
    non_flight: false,
    reason_travel: null,
    mandatory_site: true,
    mandatory_expense: false,
    status_traveller: "Employee",
    attachments: false,
  };

  // Declaration 0 for one form TA
  const [fieldsPanel, setFieldsPanel] = useState([settingsPanels]);

  const ValidateSameOrganization = (employee_detail, index) => {
    const getOrganizationHeadTraveler = options.find(
      (employee) =>
        employee.detail.employment.employee_id ===
        form.getFieldsValue()[0].employee_id
    );

    if (getOrganizationHeadTraveler) {
      // check diferent dept
      if (
        getOrganizationHeadTraveler.detail.employment.organization_id !==
        employee_detail.employment.organization_id
      ) {
        Report.info(
          "Important Information",
          "Sorry, you cannot select different department, must same of department",
          "Okay"
        );

        return form.setFieldsValue({
          [index]: {
            level: null,
            emp_status: null,
            cost_code: null,
            employee_id: null,
            user_id: null,
            no_ktp: null,
            date_birth: null,
            phone_number: null,
            place_of_birth: null,
            fullname: null,
            attachments: null,
          },
        });
      }
    }
  };

  const onSelectNameEmployee = (traveller, index) => {
    // User Id row from mongo
    let idUserRow = oldUser.find(
      (employee) =>
        String(employee.nik) === String(traveller.employment.employee_id)
    );

    if (fieldsPanel.length > 1) ValidateSameOrganization(traveller, index);

    form.setFieldsValue({
      [index]: {
        level: traveller.employment.job_level,
        emp_status: traveller.employment.employment_status,
        cost_code: getBranchByValue(traveller.employment.branch_id),
        user_id: idUserRow?._id,
        employee_id: traveller.employment.employee_id,
        // no_ktp: traveller.personal.identity_number,
        // date_birth: moment(traveller.personal.birth_date),
        // phone_number: traveller.personal.mobile_phone,
        // place_of_birth: traveller.personal.birth_place,
      },
    });

    setHeaderPanel(
      index,
      `${traveller.personal.first_name} ${traveller.personal.last_name}`
    );
  };

  const onSelectNameContractor = (traveller, index) => {
    // traveleer bisa input manual karena ada kemungkinan belum terdaftar
    form.setFieldsValue({
      [index]: {
        level: traveller?.level.fullname,
        emp_status: traveller?.employee_status,
        cost_code: traveller?.company.code,
        user_id: traveller?._id,
        employee_id: traveller?.nik,
        // no_ktp: traveller?.nik,
        date_birth: moment(traveller?.date_birth),
        phone_number: traveller?.phone_number,
        place_of_birth: traveller?.place_of_birth,
      },
    });

    setHeaderPanel(index, `${traveller?.fullname}`);
  };

  const isFormFilled = () => {
    const formValues = form.getFieldsValue();

    Object.values(formValues).forEach((i, index) => {
      if (i.expense_type === "Airline") {
        updateFieldPanel(index, "show_ticket", true);
        updateFieldPanel(index, "non_flight", false);
      } else {
        updateFieldPanel(index, "non_flight", true);
        updateFieldPanel(index, "show_ticket", false);
      }

      if (i.fullname) {
        updateFieldPanel(index, "reason_for_travel_other_disabled", false);
      }

      if (i.reason_for_travel) {
        updateFieldPanel(index, "expense_type_disabled", false);
        updateFieldPanel(index, "objective_disabled", false);
        updateFieldPanel(index, "reason_for_travel", false);
      }
    });
  };

  const setHeaderPanel = (index, data) => {
    //change header panel after select fullname
    const tempFields = [...fieldsPanel];
    tempFields[index].fullname = data;
    setFieldsPanel(tempFields);
  };

  const createTravelAuthority = (data, token) => {
    API.newTravelAuthority(HeaderFarms(token), { data: data })
      .then((res) => {
        Report.success(
          `The request was successfully`,
          "Thankyou, We will immediately process your request",
          "Okay",
          function () {
            navigate("/ta/history");
          }
        );
        Loading.remove();
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      })
      .finally(() => {
        setIsSubmitting(false); // Set status pengiriman kembali ke false setelah selesai
        handleCancel();
      });
  };

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        // Load data Airlines, Airport, Cities
        GetDataStatic();
        getUsersAutoComplete(responseToken.accessToken);
      })
      .catch((err) => {
        navigate("/");
      });
  }

  const getUsersAutoComplete = async (accessToken) => {
    const non_employee = [];

    API.getUsersTA(HeaderFarms(accessToken), null)
      .then(async (res) => {
        setOldUser(res);

        // Ambil Karyawan non employee
        res.forEach((employee) => {
          if (employee.employee_status === "Non Employee") {
            non_employee.push({
              value: employee.fullname,
              label: employee.fullname,
              detail: employee,
            });
          }
        });

        setNonEmployee(non_employee);
      })
      .catch((err) => {
        console.log(err);
        message.warning("Terjadi Kesalahan saat load data user farms");
      });
  };

  const getApprover = async (travellers) => {
    let length = travellers.length;
    let approver = [];

    if (length === 1) {
      // Check Traveller is employee or not
      if (travellers[0].employee_id || travellers[0].user_id) {
        // Get First Traveller
        let employments = options.find(
          (employee) =>
            employee.detail.employment.employee_id === travellers[0].employee_id
        );

        // Get Approver Detail based on approval line
        let approverDetail = options.find(
          (employee) =>
            employee.detail.user_id ===
            employments?.detail.employment.approval_line
        );

        // If approval line not yet, show all employee
        if (approverDetail) {
          approver.push({
            nik: approverDetail.detail.employment.employee_id,
            fullname: approverDetail.detail.personal.first_name,
            level: approverDetail.detail.employment.job_level,
          });
        } else {
          options.forEach(({ detail }) => {
            approver.push({
              nik: detail.employment.employee_id,
              fullname: detail.personal.first_name,
              level: detail.employment.job_level,
            });
          });
        }
      } else {
        options.forEach(({ detail }) => {
          approver.push({
            nik: detail.employment.employee_id,
            fullname: detail.personal.first_name,
            level: detail.employment.job_level,
          });
        });
      }
    } else {
      const uniqueNiks = new Set();

      travellers.forEach((traveller) => {
        let travellerDetail;
        const travellerEmployee = options.find(
          (employee) =>
            employee.detail.employment.employee_id === traveller.employee_id
        );
        if (travellerEmployee && travellerEmployee.detail) {
          travellerDetail = travellerEmployee.detail;
        } else {
          console.error("Traveller detail not found.");
        }

        // Get Approver by approval line
        let getApproverDetail;
        if (
          travellerDetail &&
          travellerDetail.employment &&
          travellerDetail.employment.approval_line
        ) {
          getApproverDetail = options.find(
            (employee) =>
              employee.detail.user_id ===
              travellerDetail.employment.approval_line
          );
        } else {
          console.error("Approval line not found for the traveller.");
        }

        if (getApproverDetail) {
          // Destructure getApproverDetail
          let {
            detail: { employment, personal },
          } = getApproverDetail;

          const approverEntry = {
            nik: employment.employee_id,
            fullname: personal.first_name,
            level: employment.job_level,
          };

          // Check if nik is not already in the set
          if (!uniqueNiks.has(approverEntry.nik)) {
            approver.push(approverEntry);
            uniqueNiks.add(approverEntry.nik);
          }
        }
      });

      options.forEach(({ detail }) => {
        approver.push({
          nik: detail.employment.employee_id,
          fullname: detail.personal.first_name,
          level: detail.employment.job_level,
        });
      });
    }

    setApproverList(approver);
  };

  const onFinish = async () => {
    if (isSubmitting) return; // Cek apakah sedang dalam proses pengiriman data

    setIsSubmitting(true); // Set status pengiriman menjadi true untuk menandakan bahwa sedang dalam proses pengiriman

    const confirmCallback = () => {
      const dataArray = Object.values(form.getFieldsValue()).map(
        (formData, index) => ({
          ...formData,
          attachments: fileList[index] || [],
        })
      );

      const body = {
        approver: form_admin.getFieldsValue().approver,
        dispatcher: form_admin.getFieldsValue().dispatcher,
        ta_data: dataArray,
      };

      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((responseToken) => {
          createTravelAuthority(body, responseToken.accessToken);
        })
        .catch(() => {
          navigate("/");
        });
    };

    Confirm.show(
      "Important Confirmation",
      "Are you sure? Please make sure your request is correct.",
      "Yes",
      "No",
      confirmCallback,
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const nextStep = () => {
    const dataArray = Object.values(form.getFieldsValue());
    getApprover(dataArray);
    setIsModalVisible(true);
  };

  const onChangeCollapse = () => { };

  const handleAddFields = async () => {
    try {
      await form.validateFields();

      setFieldsPanel([...fieldsPanel, settingsPanels]);
    } catch (error) {
      notification.warning({
        message: "Perhatian",
        description:
          "Mohon lengkapi formulir perjalanan anda agar bisa menambah formulir baru",
        placement: "top",
      });
    }
  };

  const handleRemoveFields = (index) => {
    Modal.confirm({
      title: "Hapus Additional ini?",
      content: `Apakah anda yakin ingin menghapus Additional untuk ${fieldsPanel[index].fullname} ini?`,
      onOk() {
        const newFieldsValue = Object.values(form.getFieldsValue()).filter(
          (_, i) => i !== index
        );
        form.resetFields();
        form.setFieldsValue(newFieldsValue);
        const tempFields = [...fieldsPanel];
        tempFields.splice(index, 1);
        setFieldsPanel(tempFields);
        notification.success({
          message: "berhasil dihapus",
        });
      },
    });
  };

  const statusTravelerHandler = (index, data) => {
    form.setFieldsValue({
      [index]: {
        fullname: null,
        level: null,
        emp_status: null,
        cost_code: null,
        user_id: null,
        no_ktp: null,
        date_birth: null,
        phone_number: null,
        place_of_birth: null,
        employee_id: null,
        attachments: null,
      },
    });

    updateFieldPanel(index, "status_traveller", data.target.value);
  };

  const updateFieldPanel = (index, key, value) => {
    const tempFields = [...fieldsPanel];
    tempFields[index][key] = value;
    setFieldsPanel(tempFields);
  };

  const siteHandler = (index, e) => {
    if (
      e.target.value === "BRM" ||
      e.target.value === "GMI" ||
      e.target.value === "CPM" ||
      e.target.value === "DPM" ||
      e.target.value === "LMR"
    ) {
      form.setFieldsValue({
        [index]: {
          expense_type: "Airline",
        },
      });

      handleExpenseType(index, e);
    } else {
      form.setFieldsValue({
        [index]: {
          expense_type: "Operational Car",
        },
      });
    }

    isFormFilled();
  };

  const reasonTravelHandler = (index, e) => {
    if (e.target.value === "Other") {
      updateFieldPanel(index, "reason_for_travel_other_hide", false);
      updateFieldPanel(index, "mandatory_expense", true);
      updateFieldPanel(index, "reason_travel", "Other");
      updateFieldPanel(index, "mandatory_site", false);
    } else if (e.target.value === "Site Visit") {
      updateFieldPanel(index, "reason_for_travel_other_hide", true);
      updateFieldPanel(index, "reason_travel", "Site Visit");
      updateFieldPanel(index, "mandatory_expense", true);
      updateFieldPanel(index, "mandatory_site", true);
    } else if (e.target.value === "Field Break") {
      updateFieldPanel(index, "reason_for_travel_other_hide", true);
      updateFieldPanel(index, "reason_travel", "Field Break");

      updateFieldPanel(index, "mandatory_expense", false);
    } else if (e.target.value === "Training") {
      updateFieldPanel(index, "reason_for_travel_other_hide", true);
      updateFieldPanel(index, "reason_travel", "Training");
      updateFieldPanel(index, "mandatory_expense", true);
      updateFieldPanel(index, "mandatory_site", false);
    } else {
      updateFieldPanel(index, "reason_for_travel_other_hide", true);

      // reset explain
      form.setFieldsValue({
        [index]: {
          reason_for_travel_other: null,
          reason_for_travel: e.target.value,
        },
      });

      updateFieldPanel(index, "reason_travel", null);
    }

    isFormFilled();
  };

  const handleExpenseType = (index, e) => {
    isFormFilled();

    if (e.target.value !== "Airline") {
      updateFieldPanel(index, "show_accomodation", false);
      updateFieldPanel(index, "show_ticket", false);
      updateFieldPanel(index, "add_travel_cost_disabled", false);

      form.setFieldsValue({
        [index]: {
          expense_other: null,
        },
      });
    }

    if (e.target.value === "Hotel") {
      updateFieldPanel(index, "show_accomodation", true);
    } else if (e.target.value === "Airline") {
      updateFieldPanel(index, "show_ticket", true);
    }

    // hide Ticket if unchecked
    if (!e.target.checked && e.target.value === "Airline") {
      updateFieldPanel(index, "show_ticket", false);
    }
    // hide hotel if unchecked
    if (!e.target.checked && e.target.value === "Hotel") {
      updateFieldPanel(index, "show_accomodation", false);
    }
  };

  const ExpenseOther = (index, e) => {
    if (e.includes("Hotel")) {
      updateFieldPanel(index, "show_accomodation", true);
    } else {
      updateFieldPanel(index, "show_accomodation", false);
    }

    if (e.includes("Cost Estimation")) {
      updateFieldPanel(index, "add_travel_cost_disabled", true);
    } else {
      updateFieldPanel(index, "add_travel_cost_disabled", false);
    }

    if (e.includes("Mess / Guest House")) {
      updateFieldPanel(index, "show_mess", true);
    } else {
      updateFieldPanel(index, "show_mess", false);
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  function currencyFormatter(value) {
    return numeral(value).format("0,0");
  }

  function currencyParser(value) {
    return numeral(value).value();
  }

  function convertFieldsToMoment(fields) {
    return fields.map((reservation) => {
      const from_date = moment(reservation.from_date);
      const time = reservation.time.map((t) => moment(t));

      return { ...reservation, from_date, time };
    });
  }

  const getCode = (obj, key) => {
    const value = obj[key];
    if (value) {
      const matches = value.match(/\((.*?)\)/);
      return matches ? matches[1] : null;
    }
    return null;
  };

  const getIataCode = (airportData, airportName) => {
    const airport = airportData.find(
      ({ iata_code }) =>
        iata_code && iata_code.toLowerCase() === airportName?.toLowerCase()
    );
    return airport ? airport.country : null;
  };

  const changeTypeTravel = (index, sub_index) => {
    const prevValues = form.getFieldsValue();

    const prevTaReservation =
      prevValues[index] && prevValues[index].ta_reservation;
    if (!prevTaReservation) {
      return;
    }

    const isDomestic =
      getIataCode(airport, getCode(prevTaReservation[sub_index], "from")) ===
      getIataCode(airport, getCode(prevTaReservation[sub_index], "to"));

    const ta_reservation = prevTaReservation.map((item, i) =>
      i === sub_index
        ? { ...item, type_travel: isDomestic ? "Domestic" : "International" }
        : item
    );

    let getSeatClass =
      dataFlightsBenefits[ta_reservation[sub_index]["type_travel"]];

    ta_reservation[sub_index].seat_class =
      getSeatClass.find((item) => item.position === prevValues[index].level)
        ?.seat_class || "Economy Class";

    form.setFieldsValue({ [index]: { ta_reservation } });
  };

  const handlePhoneNumberChange = (index, e) => {
    let phoneNumber = e.target.value;

    // Remove n on-digit characters
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Manipulate the phone number based on the given conditions
    if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.substring(1);
    } else if (phoneNumber.startsWith("62-")) {
      phoneNumber = "62" + phoneNumber.substring(3);
    }

    // Update the phone number in the form
    form.setFieldsValue({ [index]: { phone_number: phoneNumber } });
  };

  const GetDataStatic = async () => {
    try {
      const citiesRes = await API.Cities(HeaderFarms(null), null);

      setCitiesData(citiesRes.data);

      const airportsRes = await API.Airports(HeaderFarms(null), null);
      const airlinesRes = await API.Airlines(HeaderFarms(null), null);

      const airportData = airportsRes.data.map(
        ({ city, iata_code, country, name }) => ({
          value: `${city} (${iata_code})`,
          label: `${city}, ${country}`,
          description: `${iata_code} - ${name}`,
          country: country,
        })
      );

      setAirport(airportData);

      const airLinesData = airlinesRes.data.map(({ name, logo }) => ({
        value: name,
        label: name,
        logo,
      }));

      setAirLines(airLinesData);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, failed to load data static",
        "Okay"
      );
    }
  };

  const finishFailed = () => {
    message.warning("Mohon lengkapi formulir.");
  };

  const getEmployeeTalentaByBranch = async (id) => {
    try {
      let filter = [];

      const { data: employees } = await API.getEmployeeTalentaByBranch(
        HeaderFarms(null),
        id
      );

      employees.forEach((employee) => {
        filter.push({
          value:
            employee.personal.first_name + " " + employee.personal.last_name,
          label:
            employee.personal.first_name + " " + employee.personal.last_name,
          detail: employee,
        });
      });

      setOptions(filter);

      RequestToken();
      Block.remove(".loading");
    } catch (error) {
      console.error("Error in getEmployeeTalentaByBranch:", error);
    }
  };

  const getEmployeTalentaById = async (id) => {
    try {
      Block.arrows(".loading", "Mengambil data dari Talenta..");

      const {
        employee: {
          data: { employment },
        },
      } = await API.getEmployeeTalentaByEmployeeId(HeaderFarms(null), id);

      // Get branch DPM, BRM for default
      getEmployeeTalentaByBranch(employment.branch_id + ",0,55811");

      setEmployeeBranch(employment.branch_id);
    } catch (error) {
      console.error("Error in getEmployeTalentaById:", error);
    }
  };

  const getBranchByValue = (value) => {
    let branches = {
      48276: "GM",
      48274: "CPM",
      48275: "SHS",
      48277: "LMR",
      55811: "DPM",
      0: "BRM",
    };

    if (branches.hasOwnProperty(value)) {
      return branches[value];
    }
  };

  const handleUpload = (index, info) => {
    const updateFileList = [...info.fileList];

    updateFileList.forEach((file) => {
      if (!file.base64 && file.originFileObj) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64 = e.target.result;

          // Perbarui state `fileList` dengan file baru dalam format base64
          setFileList((prevFileList) => ({
            ...prevFileList,
            [index]: [
              ...(prevFileList[index] || []),
              { name: file.name, base64 },
            ],
          }));
        };

        reader.readAsDataURL(file.originFileObj);
      }
    });
  };

  const HandleUploadAttachment = (index, event) => ({
    accept: "image/jpeg",
    multiple: true,
    maxCount: 1,
    onChange: (info) => handleUpload(index, info),
    fileList: fileList[index] || [],
    onRemove: (file) => {
      setFileList((prevFileList) => {
        const updatedFileList = { ...prevFileList };
        updatedFileList[index] = updatedFileList[index].filter(
          (item) => item.uid !== file.uid
        );
        return updatedFileList;
      });
    },
    beforeUpload: (file) => {
      const isAcc = file.type === "image/jpeg";
      const isLt2MB = file.size / 1024 / 1024 < 2; // Ukuran maksimal 2 MB
      if (!isAcc) {
        Notify.info("Attachment must be in JPEG format.");
        return false;
      }
      if (!isLt2MB) {
        Notify.info("Attachment size must be less than 50MB.");
        return false;
      }
      return true;
    },
  });

  useEffect(() => {
    // Check already login or not ?
    if (!isAuthenticated) {
      navigate("/");
    }

    getEmployeTalentaById(
      props.initializeSession?.nik ||
      JSON.parse(localStorage.getItem("session_signin")).nik
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar
        title="Create"
        back={true}
        subtitle="Travel Authority"
        navigate="/ta/history"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          scrollToFirstError={true}
          name="travel_authority_form"
          onFinish={nextStep}
          onFinishFailed={finishFailed}
          autoComplete="off"
          size="small"
          layout="vertical"
        >
          <div className="container p-3" style={{ alignSelf: "center" }}>
            <div className="row">
              {fieldsPanel.map((item, index) => {
                return (
                  <Collapse
                    key={index}
                    bordered={true}
                    defaultActiveKey={[index]}
                    className="mt-1"
                  >
                    <Panel
                      header={item.fullname || `New Travel Authority`}
                      key={index}
                    >
                      <div className="col-md-12">
                        <Collapse
                          className="collapse_ta"
                          expandIconLevel={"end"}
                          bordered={true}
                          defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
                          onChange={onChangeCollapse}
                        >
                          <Panel
                            style={{ background: "#fce8b6" }}
                            header={<b>Travel Request</b>}
                            key="2"
                          >
                            <Row className="mt-3" gutter={20}>
                              <Col
                                xs={{ span: 23 }}
                                sm={{ span: 7 }}
                                xl={{ span: 7 }}
                              >
                                <Form.Item
                                  className=""
                                  label="Reason for Travel"
                                  name={[index, "reason_for_travel"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Reason for Travel",
                                    },
                                  ]}
                                >
                                  {employeeBranch === 0 ? (
                                    <Radio.Group
                                      onChange={(e) =>
                                        reasonTravelHandler(index, e)
                                      }
                                      options={[
                                        {
                                          value: "Site Visit",
                                          label: "Site Visit",
                                        },
                                        {
                                          value: "Training",
                                          label: "Training",
                                        },
                                        {
                                          value: "Other",
                                          label: "Other",
                                        },
                                      ]}
                                    />
                                  ) : (
                                    <Radio.Group
                                      onChange={(e) =>
                                        reasonTravelHandler(index, e)
                                      }
                                      options={[
                                        {
                                          value: "Site Visit",
                                          label: "Site Visit",
                                        },
                                        {
                                          value: "Training",
                                          label: "Training",
                                        },
                                        {
                                          value: "Field Break",
                                          label: "Field Break",
                                        },
                                        {
                                          value: "Other",
                                          label: "Other",
                                        },
                                      ]}
                                    />
                                  )}
                                </Form.Item>
                                <Form.Item
                                  className="mt-4"
                                  label="Explain"
                                  name={[index, "reason_for_travel_other"]}
                                  hidden={
                                    fieldsPanel[index]
                                      .reason_for_travel_other_hide
                                  }
                                  rules={[
                                    {
                                      required:
                                        !fieldsPanel[index]
                                          .reason_for_travel_other_hide,
                                      message: "Please input your reason",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="Other, please explain the reason."
                                  />
                                </Form.Item>
                              </Col>
                              {fieldsPanel[index].reason_travel &&
                                fieldsPanel[index].reason_travel !==
                                "Field Break" && (
                                  <>
                                    <Col
                                      xs={{ span: 23 }}
                                      sm={{ span: 10 }}
                                      xl={{ span: 10 }}
                                    >
                                      {index > 0 && (
                                        <Alert
                                          closable
                                          showIcon
                                          style={{ fontSize: "12px" }}
                                          message="Jika lebih dari satu orang, tujuan site tidak dapat berbeda."
                                          type="info"
                                        />
                                      )}
                                      <Form.Item
                                        className="mt-2"
                                        label="Select Site"
                                        name={[index, "site"]}
                                        rules={[
                                          {
                                            required:
                                              fieldsPanel[index].mandatory_site,
                                            message: "Please input Site",
                                          },
                                        ]}
                                      >
                                        <Radio.Group
                                          onChange={(e) =>
                                            siteHandler(index, e)
                                          }
                                        >
                                          <Radio value="BRM">BRM</Radio>
                                          <Radio value="CPM">CPM</Radio>
                                          <Radio value="DPM">DPM</Radio>
                                          <Radio
                                            value="GMI"
                                            style={{ marginBottom: "10px" }}
                                          >
                                            GMI
                                          </Radio>
                                          <Radio value="SHS">SHS</Radio>
                                          <Radio value="LMR">LMR</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                              <Col
                                xs={{ span: 23 }}
                                sm={{ span: 10 }}
                                xl={{ span: 10 }}
                              >
                                <Form.Item
                                  label="Transportation Type"
                                  name={[index, "expense_type"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Expense Type",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    onChange={(e) =>
                                      handleExpenseType(index, e)
                                    }
                                  >
                                    <Radio value="Airline">Airline</Radio>
                                    <Radio value="Bus / Shuttle">
                                      Bus / Shuttle
                                    </Radio>
                                    <Radio
                                      value="Train"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      Train
                                    </Radio>
                                    <Radio value="Sea Trans">Sea Trans</Radio>
                                    <Radio value="Operational Car">
                                      Operational Car
                                    </Radio>
                                    <Radio
                                      value="No Need"
                                      style={{ marginTop: "10px" }}
                                    >
                                      No Need
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={10}>
                              {fieldsPanel[index].reason_travel &&
                                fieldsPanel[index].reason_travel !==
                                "Field Break" && (
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 7 }}
                                    xl={{ span: 7 }}
                                  >
                                    <Form.Item
                                      label="Expense Other"
                                      name={[index, "expense_other"]}
                                      rules={[
                                        {
                                          required:
                                            fieldsPanel[index]
                                              .mandatory_expense,
                                          message: "Please input Expense Other",
                                        },
                                      ]}
                                    >
                                      <Checkbox.Group
                                        onChange={(e) => ExpenseOther(index, e)}
                                      >
                                        <Row>
                                          <Col>
                                            <Checkbox value="Hotel">
                                              Hotel
                                            </Checkbox>
                                          </Col>
                                          <Col>
                                            <Checkbox value="Cost Estimation">
                                              Additional Advance
                                            </Checkbox>
                                          </Col>
                                          <Col className="mt-2">
                                            <Checkbox value="Mess / Guest House">
                                              Mess / Guest House
                                            </Checkbox>
                                          </Col>
                                        </Row>
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </Col>
                                )}
                              {fieldsPanel[index].reason_travel ===
                                "Field Break" ? (
                                <>
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 11 }}
                                    xl={{ span: 11 }}
                                  >
                                    <Alert
                                      closable
                                      showIcon
                                      style={{ fontSize: "12px" }}
                                      message="Penting : Harap berikan tanggal awal dari waktu fieldbreak Anda, yang tidak termasuk perjalanan menuju kota yang dituju"
                                      type="info"
                                      className="mt-2 mb-2"
                                    />
                                    <Form.Item
                                      name={[index, "fieldbreak"]}
                                      label="Fieldbreak Date"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select date departure",
                                        },
                                      ]}
                                    >
                                      <RangePicker
                                        onDate={handleDateChangeDeptature}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 11 }}
                                    xl={{ span: 11 }}
                                  >
                                    <Alert
                                      closable
                                      showIcon
                                      style={{ fontSize: "12px" }}
                                      message="Mohon pastikan tanggal pekerjaan dinas Anda, karena akan terintegrasi dengan absensi. Jika melebihi tanggal yang ditentukan atau kurang, Anda harus membuat Travel Authority lagi / Lapor ke HR."
                                      type="info"
                                      className="mt-2 mb-2"
                                    />
                                    <Form.Item
                                      name={[index, "departure"]}
                                      label="Departure"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select date departure",
                                        },
                                      ]}
                                    >
                                      <RangePicker
                                        onDate={handleDateChangeDeptature}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Panel>
                          <Panel
                            style={{ background: "#fce8b6" }}
                            accordion
                            header={<b>Traveller</b>}
                            key="1"
                          >
                            <Form.Item
                              label="Status Traveller"
                              name={[index, "traveller_status"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Please input Status",
                                },
                              ]}
                            >
                              <Radio.Group
                                defaultValue={
                                  fieldsPanel[index].status_traveller
                                }
                                onChange={(e) =>
                                  statusTravelerHandler(index, e)
                                }
                              >
                                <Radio value="Employee">Employee</Radio>
                                {fieldsPanel[index].reason_travel !==
                                  "Field Break" && (
                                    <Radio value="Contractor">Non Employee</Radio>
                                  )}
                              </Radio.Group>
                            </Form.Item>

                            {fieldsPanel[index].information_not_employee && (
                              <Row gutter={10} className="mb-2 mt-1">
                                <Col>
                                  <Alert
                                    style={{ fontSize: 13 }}
                                    message="User not already save to database, please input manual"
                                    type="info"
                                    showIcon
                                  />
                                </Col>
                              </Row>
                            )}

                            {fieldsPanel[index].status_traveller ===
                              "Employee" ? (
                              <>
                                <Row gutter={10}>
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 7 }}
                                    xl={{ span: 7 }}
                                  >
                                    {index > 0 && (
                                      <Alert
                                        closable
                                        showIcon
                                        style={{ fontSize: "12px" }}
                                        message="tidak bisa berbeda department."
                                        type="info"
                                      />
                                    )}
                                    <Form.Item
                                      className="loading mt-1"
                                      label="Fullname"
                                      name={[index, "fullname"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input fullname",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        size="middle"
                                        placeholder="Pilih nama"
                                        optionFilterProp="children"
                                        onChange={(value) => {
                                          // Data from TALENTA
                                          const selectedOption = options.find(
                                            (option) => option.value === value
                                          );
                                          const detailData =
                                            selectedOption.detail;
                                          onSelectNameEmployee(
                                            detailData,
                                            index
                                          );
                                          setHeaderPanel(index, value);
                                        }}
                                        filterOption={(inputValue, option) =>
                                          option.children
                                            .toUpperCase()
                                            .indexOf(
                                              inputValue.toUpperCase()
                                            ) !== -1
                                        }
                                      >
                                        {options.map((option) => (
                                          <Select.Option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 5 }}
                                    xl={{ span: 5 }}
                                  >
                                    <Form.Item
                                      name={[index, "employee_id"]}
                                      label="Employee ID"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Employee ID",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Employee ID"
                                        type="text"
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const onlyAlphanumeric =
                                            value.replace(/[^a-zA-Z0-9]/g, ""); // Menghapus karakter selain huruf dan angka

                                          form.setFieldsValue({
                                            [index]: {
                                              no_ktp:
                                                onlyAlphanumeric.toUpperCase(),
                                            },
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row gutter={10}>
                                  <Col
                                    xs={{ span: 23 }}
                                    sm={{ span: 7 }}
                                    xl={{ span: 7 }}
                                  >
                                    <Form.Item
                                      className="loading mt-1"
                                      label="Fullname"
                                      name={[index, "fullname"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input fullname",
                                        },
                                      ]}
                                    >
                                      <AutoComplete
                                        size="middle"
                                        options={nonEmployee}
                                        onBlur={(data) => {
                                          const selectedOption =
                                            nonEmployee.find(
                                              (option) =>
                                                option.value ===
                                                data.target.value
                                            );
                                          const detailData =
                                            selectedOption?.detail;

                                          onSelectNameContractor(
                                            detailData,
                                            index
                                          );
                                          setHeaderPanel(
                                            index,
                                            data.target.value
                                          );
                                        }}
                                        onSelect={(data) => {
                                          const selectedOption =
                                            nonEmployee.find(
                                              (option) => option.value === data
                                            );
                                          const detailData =
                                            selectedOption.detail;

                                          onSelectNameContractor(
                                            detailData,
                                            index
                                          );
                                        }}
                                        filterOption={(inputValue, option) => {
                                          return (
                                            option.label
                                              .toUpperCase()
                                              .indexOf(
                                                inputValue.toUpperCase()
                                              ) !== -1
                                          );
                                        }}
                                      >
                                        <Input.Search
                                          size="middle"
                                          placeholder="Reza Muhammad"
                                          allowClear
                                        />
                                      </AutoComplete>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <Row gutter={10}>
                              <Col
                                xs={{ span: 23 }}
                                sm={{ span: 3 }}
                                xl={{ span: 3 }}
                              >
                                <Form.Item
                                  label="Cost Code"
                                  name={[index, "cost_code"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input cost code",
                                    },
                                  ]}
                                >
                                  <AutoComplete
                                    placeholder="BRM"
                                    onChange={(value) => {
                                      const uppercaseValue =
                                        value.toUpperCase();
                                      form.setFieldsValue({
                                        [index]: {
                                          cost_code: uppercaseValue,
                                        },
                                      });
                                    }}
                                    dataSource={[
                                      "CPM",
                                      "GMI",
                                      "LMR",
                                      "BRM",
                                      "SHS",
                                      "DPM",
                                    ]}
                                    filterOption={(inputValue, option) =>
                                      option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                      -1
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xs={{ span: 23 }}
                                sm={{ span: 5 }}
                                xl={{ span: 5 }}
                              >
                                <Form.Item
                                  label="Level"
                                  name={[index, "level"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select level",
                                    },
                                  ]}
                                >
                                  <AutoComplete
                                    placeholder="Level"
                                    filterOption={(inputValue, option) =>
                                      option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                      -1
                                    }
                                  >
                                    <Option value="Chief">Chief</Option>
                                    <Option value="Vice President">
                                      Vice President
                                    </Option>
                                    <Option value="Director">Director</Option>
                                    <Option value="General Manager">
                                      General Manager
                                    </Option>
                                    <Option value="Manager">Manager</Option>
                                    <Option value="Superintendent">
                                      Superintendent
                                    </Option>
                                    <Option value="Supervisor">
                                      Supervisor
                                    </Option>
                                    <Option value="Foreman/Officer">
                                      Foreman/Officer
                                    </Option>
                                    <Option value="Non Staff">Non Staff</Option>
                                    <Option value="Magang">Magang</Option>
                                  </AutoComplete>
                                </Form.Item>
                              </Col>
                              <Col
                                xs={{ span: 23 }}
                                sm={{ span: 4 }}
                                xl={{ span: 4 }}
                              >
                                <Form.Item
                                  label="Employee Status"
                                  name={[index, "emp_status"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select employee status",
                                    },
                                  ]}
                                >
                                  <AutoComplete
                                    placeholder="Employee Status"
                                    filterOption={(inputValue, option) =>
                                      option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                      -1
                                    }
                                  >
                                    {fieldsPanel[index].status_traveller ===
                                      "Employee" ? (
                                      <>
                                        <Option value="Contract">
                                          Contract
                                        </Option>
                                        <Option value="Permanent">
                                          Permanent
                                        </Option>
                                        <Option value="Internship">
                                          Internship
                                        </Option>
                                      </>
                                    ) : (
                                      <Option value="Non Employee">
                                        Non Employee
                                      </Option>
                                    )}
                                  </AutoComplete>
                                </Form.Item>
                              </Col>
                              <Form.Item
                                name={[index, "user_id"]}
                                rules={[{ required: false }]}
                              >
                                <Input hidden />
                              </Form.Item>
                            </Row>
                            {fieldsPanel[index].status_traveller !==
                              "Employee" && (
                                <>
                                  <Row gutter={10}>
                                    <Col
                                      xs={{ span: 23 }}
                                      sm={{ span: 5 }}
                                      xl={{ span: 5 }}
                                    >
                                      <Form.Item
                                        name={[index, "phone_number"]}
                                        label="Whatsapp Number"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please input Phone Number",
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value && isNaN(value)) {
                                                return Promise.reject(
                                                  "Please enter a valid Phone Number"
                                                );
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="text"
                                          placeholder="example: 628597721233"
                                          inputMode="numeric"
                                          pattern="[0-9]*"
                                          onChange={(e) =>
                                            handlePhoneNumberChange(index, e)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      xs={{ span: 23 }}
                                      sm={{ span: 5 }}
                                      xl={{ span: 5 }}
                                    >
                                      <Form.Item
                                        name={[index, "no_ktp"]}
                                        label="No KTP / Passport"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input KTP / Passport number",
                                          },
                                          {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                              "Please enter a valid KTP / Passport number",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="No KTP / Passport"
                                          type="text"
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            const onlyAlphanumeric =
                                              value.replace(/[^a-zA-Z0-9]/g, ""); // Menghapus karakter selain huruf dan angka

                                            form.setFieldsValue({
                                              [index]: {
                                                no_ktp:
                                                  onlyAlphanumeric.toUpperCase(),
                                              },
                                            });
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={10}>
                                    <Col
                                      xs={{ span: 23 }}
                                      sm={{ span: 5 }}
                                      xl={{ span: 5 }}
                                    >
                                      <Form.Item
                                        label="Place Of Birth ( City )"
                                        name={[index, "place_of_birth"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input Place of Birth",
                                          },
                                        ]}
                                      >
                                        <AutoComplete
                                          options={
                                            citiesData &&
                                            citiesData.map((city) => ({
                                              value: city.city,
                                              label: city.city,
                                            }))
                                          }
                                          filterOption={(inputValue, option) => {
                                            return (
                                              option.label
                                                .toUpperCase()
                                                .indexOf(
                                                  inputValue.toUpperCase()
                                                ) !== -1
                                            );
                                          }}
                                        >
                                          <Input.Search
                                            size="small"
                                            placeholder="Jakarta"
                                          />
                                        </AutoComplete>
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      xs={{ span: 23 }}
                                      sm={{ span: 5 }}
                                      xl={{ span: 5 }}
                                    >
                                      <Form.Item
                                        name={[index, "date_birth"]}
                                        label="Date Birth"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please select date of birth",
                                          },
                                        ]}
                                      >
                                        <DatePicker
                                          // disabledDate={(current) => current && current.year() > 2004} // Membatasi tahun sebelum 2004
                                          defaultValue={moment("2004-01-01")} // Nilai default diatur ke tanggal 1 Januari 2004
                                          format="YYYY-MM-DD"
                                          placeholder="Select date"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              )}
                          </Panel>
                          {fieldsPanel[index].non_flight && (
                            <Panel
                              style={{ background: "#fce8b6" }}
                              header={<b>Destination</b>}
                              key="6"
                            >
                              <Form.List
                                name={[index, "ta_reservation"]}
                                initialValue={
                                  fieldsPanel[index].ta_reservation
                                    ? convertFieldsToMoment(
                                      fieldsPanel[index].ta_reservation
                                    )
                                    : [{}]
                                }
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, sub_index) => (
                                      <React.Fragment key={field.key}>
                                        {sub_index !== fields.length && (
                                          <Divider
                                            style={{
                                              color: "blue",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >{`Destination - ${sub_index + 1
                                            }`}</Divider>
                                        )}
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Date (Estimation Date)"
                                              name={[field.name, "from_date"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please input Dates",
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                placeholder={moment()
                                                  .add(1, "day")
                                                  .format("YYYY-MM-DD")}
                                              />
                                            </Form.Item>
                                          </Col>

                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Time (Estimation Time)"
                                              name={[field.name, "time"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please input Times",
                                                },
                                              ]}
                                            >
                                              <InputMask
                                                mask="99:99"
                                                value={selectedTime}
                                                onChange={handleTimeChange}
                                                maskChar={null}
                                                placeholder="00:00 - 23:59"
                                              >
                                                {(inputProps) => (
                                                  <input
                                                    className="ant-input"
                                                    {...inputProps}
                                                  />
                                                )}
                                              </InputMask>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="From"
                                              name={[field.name, "from"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the city",
                                                },
                                              ]}
                                              getValueFromEvent={(e) =>
                                                e.target.value.toUpperCase()
                                              }
                                            >
                                              <Input
                                                onChange={() =>
                                                  changeTypeTravel(
                                                    index,
                                                    field.name
                                                  )
                                                }
                                                placeholder="Name City"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="To"
                                              name={[field.name, "to"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the Name City",
                                                },
                                              ]}
                                              getValueFromEvent={(e) =>
                                                e.target.value.toUpperCase()
                                              }
                                            >
                                              <Input
                                                onChange={() =>
                                                  changeTypeTravel(
                                                    index,
                                                    field.name
                                                  )
                                                }
                                                placeholder="Name City"
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>

                                        {sub_index > 0 && (
                                          <Button
                                            icon={<PlusCircleFilled />}
                                            size="middle"
                                            type="danger"
                                            onClick={() => remove(field.name)}
                                          >
                                            Remove Destination
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    ))}
                                    <Divider />
                                    <Row gutter={10}>
                                      <Col
                                        xs={{ span: 23 }}
                                        sm={{ span: 7 }}
                                        xl={{ span: 7 }}
                                      >
                                        <Form.Item>
                                          <Button
                                            size="middle"
                                            type="primary"
                                            onClick={() => add()}
                                            icon={<PlusCircleFilled />}
                                          >
                                            Add Destination
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Form.List>
                            </Panel>
                          )}

                          {fieldsPanel[index].show_ticket && (
                            <Panel
                              style={{ background: "#fce8b6" }}
                              header={<b>Reservation</b>}
                              key="6"
                            >
                              <Form.List
                                name={[index, "ta_reservation"]}
                                initialValue={
                                  fieldsPanel[index].ta_reservation
                                    ? convertFieldsToMoment(
                                      fieldsPanel[index].ta_reservation
                                    )
                                    : [{}]
                                }
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, sub_index) => (
                                      <React.Fragment key={field.key}>
                                        {sub_index !== fields.length && (
                                          <Divider
                                            style={{
                                              color: "red",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >{`Ticket - ${sub_index + 1
                                            }`}</Divider>
                                        )}
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Date (Estimation Date)"
                                              name={[field.name, "from_date"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please input Dates",
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                placeholder={moment()
                                                  .add(1, "day")
                                                  .format("YYYY-MM-DD")}
                                              />
                                            </Form.Item>
                                          </Col>

                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Time (Estimation Time)"
                                              name={[field.name, "time"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please input Times",
                                                },
                                              ]}
                                            >
                                              <InputMask
                                                mask="99:99"
                                                value={selectedTime}
                                                onChange={handleTimeChange}
                                                maskChar={null}
                                                placeholder="00:00 - 23:59"
                                              >
                                                {(inputProps) => (
                                                  <input
                                                    className="ant-input"
                                                    {...inputProps}
                                                  />
                                                )}
                                              </InputMask>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="From"
                                              name={[field.name, "from"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the Airport codes",
                                                },
                                              ]}
                                            >
                                              <AutoComplete
                                                onChange={() =>
                                                  changeTypeTravel(
                                                    index,
                                                    field.name
                                                  )
                                                }
                                                placeholder="Enter Airport Code, Name or City"
                                                filterOption={(
                                                  inputValue,
                                                  option
                                                ) => {
                                                  const value =
                                                    option.value.toUpperCase();
                                                  const label =
                                                    option.label.toUpperCase();
                                                  const description =
                                                    option.description
                                                      ? option.description.toUpperCase()
                                                      : "";

                                                  return (
                                                    value.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1 ||
                                                    label.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1 ||
                                                    description.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1
                                                  );
                                                }}
                                              >
                                                {airport &&
                                                  airport.map((option) => (
                                                    <Option
                                                      key={option.value}
                                                      value={option.value}
                                                      label={option.label}
                                                      country={option.country}
                                                      description={
                                                        option.description
                                                      } // tambahkan description sebagai properti
                                                    >
                                                      {option.label}
                                                      {option.description && (
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            color: "#aaa",
                                                          }}
                                                        >
                                                          {option.description}
                                                        </div>
                                                      )}
                                                    </Option>
                                                  ))}
                                              </AutoComplete>
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="To"
                                              name={[field.name, "to"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the Airport codes",
                                                },
                                              ]}
                                            >
                                              <AutoComplete
                                                onChange={() =>
                                                  changeTypeTravel(
                                                    index,
                                                    field.name
                                                  )
                                                }
                                                placeholder="Enter Airport Code, Name or City"
                                                filterOption={(
                                                  inputValue,
                                                  option
                                                ) => {
                                                  const value =
                                                    option.value.toUpperCase();
                                                  const label =
                                                    option.label.toUpperCase();
                                                  const description =
                                                    option.description
                                                      ? option.description.toUpperCase()
                                                      : "";

                                                  return (
                                                    value.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1 ||
                                                    label.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1 ||
                                                    description.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1
                                                  );
                                                }}
                                              >
                                                {airport &&
                                                  airport.map((option) => (
                                                    <Option
                                                      key={option.value}
                                                      value={option.value}
                                                      label={option.label}
                                                      country={option.country}
                                                      description={
                                                        option.description
                                                      } // tambahkan description sebagai properti
                                                    >
                                                      {option.label}
                                                      {option.description && (
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            color: "#aaa",
                                                          }}
                                                        >
                                                          {option.description}
                                                        </div>
                                                      )}
                                                    </Option>
                                                  ))}
                                              </AutoComplete>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Airline"
                                              name={[field.name, "airline"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the Airline",
                                                },
                                              ]}
                                            >
                                              <AutoComplete
                                                onSearch={null}
                                                placeholder="Enter airline"
                                                filterOption={(
                                                  inputValue,
                                                  option
                                                ) => {
                                                  const value =
                                                    option.value.toUpperCase();
                                                  const label =
                                                    option.label.toUpperCase();

                                                  return (
                                                    value.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1 ||
                                                    label.indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1
                                                  );
                                                }}
                                              >
                                                {airlines &&
                                                  airlines.map((option) => (
                                                    <Option
                                                      key={option.value}
                                                      value={option.value}
                                                      label={option.label}
                                                    >
                                                      {option.label}
                                                      <img
                                                        src={option.logo} // sumber logo maskapai
                                                        alt={option.label} // alternatif teks untuk aksesibilitas
                                                        style={{
                                                          float: "right", // letakkan gambar di sebelah kanan opsi
                                                          height: 20, // tentukan ukuran gambar yang sesuai
                                                          marginLeft: 10, // tambahkan jarak antara gambar dan teks
                                                        }}
                                                      />
                                                    </Option>
                                                  ))}
                                              </AutoComplete>
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Type Travel"
                                              name={[field.name, "type_travel"]}
                                            >
                                              <Select placeholder="Domestic / International">
                                                <Select.Option value="Domestic">
                                                  Domestic
                                                </Select.Option>
                                                <Select.Option value="International">
                                                  International
                                                </Select.Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Seat Class"
                                              name={[field.name, "seat_class"]}
                                            >
                                              <Input
                                                readOnly
                                                placeholder="Economic / Business Class"
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        {sub_index > 0 && (
                                          <Button
                                            icon={<PlusCircleFilled />}
                                            size="middle"
                                            type="danger"
                                            onClick={() => remove(field.name)}
                                          >
                                            Remove Ticket
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    ))}
                                    <Divider />
                                    <Row gutter={10}>
                                      <Col
                                        xs={{ span: 23 }}
                                        sm={{ span: 7 }}
                                        xl={{ span: 7 }}
                                      >
                                        <Form.Item>
                                          <Button
                                            size="middle"
                                            type="primary"
                                            onClick={() => add()}
                                            icon={<PlusCircleFilled />}
                                          >
                                            Add Ticket Flight
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Form.List>
                            </Panel>
                          )}

                          {fieldsPanel[index].show_accomodation && (
                            <Panel
                              style={{ background: "#fce8b6" }}
                              header={<b>Accomodation</b>}
                              key="3"
                            >
                              <Form.List
                                name={[index, "ta_accomodation"]}
                                initialValue={
                                  fieldsPanel[index].ta_accomodation
                                    ? convertFieldsToMoment(
                                      fieldsPanel[index].ta_accomodation
                                    )
                                    : [{}]
                                }
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, index) => (
                                      <React.Fragment key={field.key}>
                                        {index !== fields.length && (
                                          <Divider
                                            style={{
                                              color: "red",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >{`Accomodation - ${index + 1
                                            }`}</Divider>
                                        )}
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Date"
                                              name={[
                                                field.name,
                                                "accomodation_date",
                                              ]}
                                              hidden={null}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input Date In and Date Out",
                                                },
                                              ]}
                                            >
                                              <RangePicker
                                                onDate={
                                                  handleDateChangeAccomodation
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Hotel Name"
                                              name={[
                                                field.name,
                                                "accomodation",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input Hotel Name",
                                                },
                                              ]}
                                            >
                                              <AutoComplete
                                                placeholder="Enter Hotel Name"
                                                filterOption={(
                                                  inputValue,
                                                  option
                                                ) =>
                                                  option.value
                                                    .toUpperCase()
                                                    .indexOf(
                                                      inputValue.toUpperCase()
                                                    ) !== -1
                                                }
                                              >
                                                <Option value="The Grove Suites">
                                                  The Grove Suites, Jakarta
                                                </Option>
                                                <Option value="Hotel JS Luwansa">
                                                  Hotel JS Luwansa, Jakarta
                                                </Option>
                                                <Option value="Swiss-Belresidences Rasuna Epicentrum">
                                                  Swiss-Belresidences Rasuna
                                                  Epicentrum, Jakarta
                                                </Option>
                                                <Option value="Royal Kuningan Hotel">
                                                  Royal Kuningan Hotel, Jakarta
                                                </Option>
                                                <Option value="Hotel Santika Palu">
                                                  Hotel Santika, Palu
                                                </Option>
                                                <Option value="Best Western Plus Coco Palu">
                                                  Best Western Plus Coco, Palu
                                                </Option>
                                                <Option value="Sutan Raja Hotel & Convention Palu">
                                                  Sutan Raja Hotel & Convention,
                                                  Palu
                                                </Option>
                                                <Option value="Swiss-Belhotel Silae Palu">
                                                  Swiss-Belhotel Silae, Palu
                                                </Option>
                                                <Option value="Grand Q Hotel Gorontalo">
                                                  Grand Q Hotel, Gorontalo
                                                </Option>
                                                <Option value="ASTON Hotel & Villas Gorontalo">
                                                  ASTON Hotel & Villas,
                                                  Gorontalo
                                                </Option>
                                                <Option value="Parkside Gayo Petro Takengon">
                                                  Parkside Gayo Petro, Takengon
                                                </Option>
                                                <Option value="Hotel Riyadh Takengon">
                                                  Hotel Riyadh, Takengon
                                                </Option>
                                                <Option value="Grand Renggali Hotel Takengon">
                                                  Grand Renggali Hotel, Takengon
                                                </Option>
                                                <Option value="Hotel Grand Bayu Hill Takengon">
                                                  Hotel Grand Bayu Hill,
                                                  Takengon
                                                </Option>
                                              </AutoComplete>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        {index > 0 && (
                                          <Button
                                            size="middle"
                                            icon={<PlusCircleFilled />}
                                            type="danger"
                                            onClick={() => remove(field.name)}
                                          >
                                            Remove Accomodation
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    ))}
                                    <Divider />
                                    <Form.Item>
                                      <Button
                                        size="middle"
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusCircleFilled />}
                                      >
                                        Add Accomodation
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Panel>
                          )}

                          {fieldsPanel[index].add_travel_cost_disabled && (
                            <Panel
                              style={{ background: "#fce8b6" }}
                              header={<b>Travel Cost Estimation</b>}
                              key="4"
                            >
                              <Form.List
                                name={[index, "ta_cost_est"]}
                                initialValue={[{}]}
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, index) => (
                                      <React.Fragment key={field.key}>
                                        {index !== fields.length && (
                                          <Divider
                                            style={{
                                              color: "red",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                            }}
                                          >{`Travel Cost Estimation - ${index + 1
                                            }`}</Divider>
                                        )}
                                        <Row gutter={10}>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Description"
                                              name={[field.name, "item"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input Description",
                                                },
                                              ]}
                                            >
                                              <Input
                                                disabled={null}
                                                placeholder="Example : Pesawat PP"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            xs={{ span: 23 }}
                                            sm={{ span: 7 }}
                                            xl={{ span: 7 }}
                                          >
                                            <Form.Item
                                              label="Cost"
                                              name={[field.name, "total"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input the cost",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                min={0}
                                                step={1}
                                                disabled={null}
                                                formatter={(value) =>
                                                  `Rp ${currencyFormatter(
                                                    value
                                                  )}`
                                                }
                                                parser={(value) =>
                                                  currencyParser(value)
                                                }
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        {index > 0 && (
                                          <Button
                                            icon={<PlusCircleFilled />}
                                            size="middle"
                                            type="danger"
                                            onClick={() => remove(field.name)}
                                          >
                                            Remove Cost Estimation
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    ))}
                                    <Divider />
                                    <Form.Item>
                                      <Button
                                        disabled={null}
                                        size="middle"
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusCircleFilled />}
                                      >
                                        Add Travel Cost
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Panel>
                          )}
                          <Panel
                            style={{ background: "#fce8b6" }}
                            header={<b>Objective</b>}
                            key="5"
                          >
                            <Row gutter={10}>
                              <Col xs={{ span: 23 }} span={12}>
                                <Form.Item
                                  label={
                                    <>
                                      <span
                                        style={{
                                          fontSize: 10,
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Objective ( Reason and purpose of your
                                        travel request )
                                      </span>
                                    </>
                                  }
                                  name={[index, "objective"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Reason and purpose of your travel request",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    disabled={
                                      fieldsPanel[index].objective_disabled
                                    }
                                    rows={4}
                                    className="mt-3"
                                    placeholder="Explain your reason and purpose here..."
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={[index, "attachments"]}
                                  label="Attachments ( Image / .jpeg )"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload attachment",
                                    },
                                  ]}
                                >
                                  <div className="btn-upload-section">
                                    <Upload
                                      {...HandleUploadAttachment(index)}>

                                      <Button
                                        icon={<UploadOutlined />}
                                        style={{
                                          width: "100%",
                                        }}
                                        size="large"
                                      >
                                        Upload
                                      </Button>
                                    </Upload>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                        {index > 0 && (
                          <Button
                            size="middle"
                            type="danger"
                            htmlType="button"
                            style={{ marginTop: 5 }}
                            icon={<MinusCircleFilled />}
                            onClick={() => handleRemoveFields(index)}
                          >
                            {" "}
                            Remove {fieldsPanel[index].fullname}
                          </Button>
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                );
              })}
            </div>

            {fieldsPanel[0].reason_travel !== "Field Break" && (
              <Row className="justify-content-start mt-3">
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => handleAddFields()}
                      icon={<PlusCircleFilled />}
                      size="middle"
                      htmlType="button"
                    >
                      Add Additional Travel Authority
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <div className="row">
              <div className="col-md-12">
                <Alert
                  message="Informational Notes"
                  description={
                    <ul className="note-sr">
                      <li>
                        Jika penerbangan Internasional maka perlu persetujuan
                        Direksi
                      </li>
                    </ul>
                  }
                  type="info"
                  showIcon
                  className="note-ta"
                />
              </div>
            </div>
          </div>
          <Row className="justify-content-center">
            <Col>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                  Continue
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Modal
        title="Prefered Admin"
        footer={null}
        visible={isModalVisible}
        onOk={null}
        onCancel={handleCancel}
      >
        <div className="col-md-12 mb-3">
          <Alert
            message="Informasi Penting"
            description={
              "Jika keberangkatan diundur atau dibatalkan setelah mendapatkan persetujuan dari atasan, HR dan CFO, maka wajib membatalkan permohonan perjalanan dinas (TA) dan lapor HR terkait, karena hal tersebut akan berdampak pada catatan kehadiran."
            }
            type="warning"
            showIcon
            closable
          />
        </div>
        <Form
          form={form_admin}
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Select admin"
            name="dispatcher"
            rules={[
              {
                required: true,
                message: "Please input request too",
              },
            ]}
          >
            <Select
              style={{
                width: 300,
              }}
              options={[
                {
                  nik: "10167",
                  fullname: "Tessa Azani Aprilita",
                  company: "BRM",
                },
                {
                  nik: "10106",
                  fullname: "Rizky Anindita Widyanti",
                  company: "BRM - Finance",
                },
                {
                  nik: "10172",
                  fullname: "Siti Zubaidah Chaidir",
                  company: "BRM",
                },
                {
                  nik: "10182",
                  fullname: "Siti Maharani",
                  company: "SHS",
                },
                {
                  nik: "30069",
                  fullname: "Lian A Latief",
                  company: "GMR",
                },
                {
                  nik: "10208",
                  fullname: "Jumiawati",
                  company: "LMR",
                },
                {
                  nik: "20140",
                  fullname: "Vania Buati Fitri",
                  company: "CPM",
                },
              ].map((item) => ({
                value: item.nik,
                label: `${item.fullname} - ${item.company} `,
              }))}
            />
          </Form.Item>
          {
            <>
              {approverList.length > 0 ? (
                <Form.Item
                  label="Select Approver"
                  name="approver"
                  rules={[
                    {
                      required: true,
                      message: "Ple",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: 300,
                    }}
                    showSearch
                    placeholder="Select an approver"
                    optionFilterProp="children"
                    filterOption={(inputValue, option) => {
                      return (
                        option.children
                          .join("")
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {approverList.map((option) => (
                      <Option key={option.nik} value={option.nik}>
                        {option.fullname} - {option.level}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item name={"approver"} rules={[{ required: true }]}>
                  <Input hidden />
                </Form.Item>
              )}
            </>
          }

          <Form.Item
            className="mt-3"
            wrapperCol={{
              xs: {
                offset: 8,
                span: 18,
              },
              sm: {
                offset: 7,
                span: 18,
              },
              lg: { offset: 8, span: 18 },
            }}
          >
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Apply TA
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Layout.Footer style={{ textAlign: "center", background: "#F9F9F9" }}>
        ©2023 Information Technology
      </Layout.Footer>
    </>
  );
}

const reduxState = (state) => ({
  initializeSession: state.session,
});

export default connect(reduxState, null)(CreateTravelAuthority);
