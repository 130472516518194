import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import moment from 'moment';

import {
    Avatar, Layout, Alert, Collapse, Form, Input, Row,
    Col, Select, Button, message, Radio, DatePicker,
    InputNumber, Descriptions, Timeline, Comment, Affix, Modal
} from "antd";

import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled
} from '@ant-design/icons';



import Navbar from '../../atoms/navbar';
import API from '../../services';

import { Block, Confirm, Loading, Report } from 'notiflix';
import { loginRequest } from '../../config/api-config';
import HeaderFarms from '../../utils/header_farms';

import departmentHardCode from './data/department.json'
import categoryAsset from './data/asset-category.json'
import activityHardCode from './data/activity.json'
import projectHardCode from './data/project.json'
import vendorHardCode from './data/vendor.json'
import assetHardCode from './data/asset.json'
import areaHardCode from './data/area.json'
import { QRCodeSVG } from 'qrcode.react';

function CreateCapex() {

    const [form] = Form.useForm();
    let navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    const { instance, accounts } = useMsal();
    const { TextArea } = Input;

    const { Option } = Select;
    const { Panel } = Collapse;
    let { id } = useParams();

    const [statusColor, setStatusColor] = useState({ background: null, color: null })
    const [departmentErp, setDepartmentErp] = useState([]);
    const [categoryAssetErp, setCategoryAssetErp] = useState([]);
    const [activityErp, setActivity] = useState([])
    const [vendorErp, setVendorErp] = useState([]);
    const [projectErp, setProject] = useState([]);
    const [assetErp, setAssetErp] = useState([]);
    const [areaErp, setAreaErp] = useState([]);


    const [currentSession, setCurrentSession] =
        useState(JSON.parse(localStorage.getItem('session_signin')))

    const [usersBranch, setUsersBranch] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isModalVisibleReject, setIsModalVisibleReject] = useState(false)

    const [assetMaintenanceBy, setAssetMaintenanceBy] = useState(null);
    const [replacementOrNew, setReplacementOrNew] = useState(null);
    const [currentCurrency, setCurrentCurrency] = useState("IDR")
    const [assetOperateBy, setAssetOperateBy] = useState(null);
    const [attachedAsset, setAttachedAsset] = useState(null);
    const [financeStaff, setFinanceStaff] = useState(true);

    const [detailCapex, setDetailCapex] = useState(null);

    const handleReplacementOrNew = (a) => {
        setReplacementOrNew(a.target.value)
    }

    const handleAttachedAsset = (a) => {
        setAttachedAsset(a.target.value)
    }

    const handleAssetMaintenanceBy = (a) => {
        setAssetMaintenanceBy(a.target.value)
    }

    const handleAssetOperateBy = (a) => {
        setAssetOperateBy(a.target.value)
    }

    const handleCurrentCurrency = (value) => {
        setCurrentCurrency(value)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalReject = () => {
        setIsModalVisibleReject(true);
    };

    const handleModalReject = () => {
        setIsModalVisibleReject(false);
    };

    const onFinish = (data) => {
        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {
                if (!id)
                    saveCapex(data)
                else
                    editDataCR()
            })
    }

    const getDepartment = async (token) => {

        try {

            Block.arrows('.load-department', "Please wait for department data.");

            const headers = HeaderFarms(token);

            const { data } = await API.DepartementCapex(headers);

            setDepartmentErp(data)

            Block.remove('.load-department');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getAsset = async (token) => {

        try {

            Block.arrows('.load-asset', "Please wait for asset data.");

            const headers = HeaderFarms(token);

            const { data } = await API.AssetCapex(headers);

            setAssetErp(data)

            Block.remove('.load-asset');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getArea = async (token) => {

        try {

            Block.arrows('.load-area', "Please wait for area data.");

            const headers = HeaderFarms(token);

            const { data } = await API.AreaCapex(headers);

            setAreaErp(data)

            Block.remove('.load-area');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getVendor = async (token) => {

        try {

            Block.arrows('.load-vendor', "Please wait for vendor data.");

            const headers = HeaderFarms(token);

            const { data } = await API.VendorCapex(headers);

            setVendorErp(data)

            Block.remove('.load-vendor');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getActivity = async (token) => {

        try {

            Block.arrows('.load-activity', "Please wait for activity data.");

            const headers = HeaderFarms(token);

            const { data } = await API.ActivityCapex(headers);

            setActivity(data)

            Block.remove('.load-activity');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getProject = async (token) => {

        try {

            Block.arrows('.load-project', "Please wait for project data.");

            const headers = HeaderFarms(token);

            const { data } = await API.ProjectsCapex(headers);

            setProject(data)

            Block.remove('.load-project');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const getassetGroup = async (token) => {

        try {

            Block.arrows('.load-assetg', "Please wait for asset group data.");

            const headers = HeaderFarms(token);

            const { data } = await API.AssetGroupCapex(headers);

            setCategoryAssetErp(data)

            Block.remove('.load-assetg');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const loadDataFromEpicor = async (token) => {
        await getDepartment(token)
        await getassetGroup(token)
        await getActivity(token)
        await getProject(token)
        await getVendor(token)
        await getAsset(token)
        await getArea(token)
    }

    const getUsersByBranch = async (token) => {

        let getSession = JSON.parse(localStorage.getItem('session_signin')).nik

        try {

            Block.arrows('.load-users', "Please wait for load data.");

            const headers = HeaderFarms(token);

            const { data } = await API.UsersBranchCapex(headers);

            let filter = [];

            data.forEach(employee => {

                let position = [102512, 102510, 763066]

                if (position.includes(employee.job_level_id))
                    filter.push({
                        value: employee.fullname,
                        label: employee.fullname + " (" + employee.employee_id + ")",
                        position: employee.job_position,
                        employee_id: employee.employee_id
                    });
            })


            setUsersBranch(filter)

            Block.remove('.load-users');
        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }
    };

    const viewCapex = async (token) => {

        try {

            Block.hourglass('.ant-collapse');

            const headers = HeaderFarms(token);

            const { data } = await API.ViewCapex(headers, id);

            Block.remove('.ant-collapse');
            data.date = moment(data.date)

            setCurrentCurrency(data.currency)
            setAssetMaintenanceBy(data.asset_maintenance_by)
            setReplacementOrNew(data.status_request)
            setAssetOperateBy(data.operate_asset_by)
            setAttachedAsset(data.asset_status)
            form.setFieldsValue(data)
            checkIsFinanceStaff(data)
            setDetailCapex(data)
            styleStatus(data)


        } catch (error) {
            console.error(error);
        } finally {
            Loading.remove();
        }

        function checkIsFinanceStaff(data) {
            const checkCurrentIsPIC = data.approval_process_id.data.form_id.form_setting[0].pic
                .filter(employeeId => employeeId
                    === currentSession.nik).length > 0;

            setFinanceStaff(checkCurrentIsPIC);
        }
    };

    const cancel = (values) => {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {

            setIsModalVisible(false)

            API.ApprovalCancel(HeaderFarms(response.accessToken), {
                id: detailCapex.approval_process_id.data._id,
                reason: values.message
            }).then((_) => {

                Report.success(
                    `Cancel Success`,
                    `Capex Request berhasil di batalkan`,
                    "Okay", () => {
                        return navigate('/capex/history')
                    })


            }).catch((err) => {
                console.error(err)
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
            })

        }).catch((e) => {
            console.error(e)
        });

    }

    const approved = () => {

        Loading.hourglass("Mohon tunggu sedang diproses...");

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {

            API.ApprovalApproved(HeaderFarms(response.accessToken), {
                id: getIdProcessApproval()
            }).then((_) => {

                Loading.remove()

                Report.success(
                    `Approve Success`,
                    `Capex Request berhasil di setujui`,
                    "Okay", () => {
                        return navigate('/wf/pending')
                    })


            }).catch((err) => {
                console.error(err)
                Block.remove('.ant-collapse');
                Loading.remove()

                Report.warning(
                    "Oops.. something wrong",
                    err.toString(),
                    "Okay"
                );
            })

        }).catch((e) => {
            console.error(e)
        });

    }

    const getIdProcessApproval = () => {

        let getDetailProcess;

        const getApprover = detailCapex.approval_process_id.data
            .detail.filter(item => item.approver_id === currentSession.nik
                && item.status === false)

        if (getApprover.length > 0) {
            getDetailProcess = getApprover[0]._id
        }

        if (getApprover.length === 0) {

            const getApprover = detailCapex.approval_process_id.data
                .detail.filter(item => item.approver_id ===
                    detailCapex.approval_process_id.data.form_id.form_setting[0].pic_approval_refer
                    && item.status === false)

            getDetailProcess = getApprover[0]?._id || null
        }

        if (!getDetailProcess) {
            Report.warning(
                "Oops.. something wrong",
                "Please check approval status, maybe already approve",
                "Okay"
            );
        }

        return getDetailProcess
    }

    const reject = (values) => {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {

            setIsModalVisibleReject(false)

            API.ApprovalReject(HeaderFarms(response.accessToken), {
                id: getIdProcessApproval(),
                reason: values.message
            }).then((_) => {

                Report.success(
                    `Cancel Success`,
                    `Capex Request berhasil di tolak`,
                    "Okay", () => {
                        return navigate('/capex/history')
                    })


            }).catch((err) => {
                console.error(err)
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
            })

        }).catch((e) => {
            console.error(e)
        });

    }

    const saveCapex = async (data) => {
        try {
            Loading.hourglass("Mohon tunggu sedang diproses...");

            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            const responseToken = await instance.acquireTokenSilent(request);
            const accessToken = responseToken.accessToken;

            const headers = HeaderFarms(accessToken);
            data.currency = currentCurrency;

            await API.CreateCapex(headers, data);

            Report.success(
                "Your response has been recorded.",
                "Thank you",
                "Okay",
                function () {
                    navigate('/capex/history')
                }
            );

        } catch (error) {
            console.error(error);
            Report.warning(
                "Oops... something went wrong.",
                "Sorry, this application is experiencing problems.",
                "Okay"
            );
        } finally {
            Loading.remove();
        }
    };

    const calculateTotalAmount = () => {
        const estimatedPrice = form.getFieldValue('estimated_price') || 0;
        const quantity = form.getFieldValue('qty') || 0;
        const total = estimatedPrice * quantity;
        form.setFieldsValue({ total_estimated: total });
    };

    const calculateTotalAmountRental = () => {
        const estimatedPrice = form.getFieldValue('estimated_rental_cost') || 0;
        const quantity = form.getFieldValue('estimated_rental_period') || 0;
        const total = estimatedPrice * quantity;
        form.setFieldsValue({ total_estimated_rental_cost: total });
    };

    const selectBefore = (
        <>
            <Select
                value={currentCurrency}
                name="currency"
                onChange={handleCurrentCurrency}
                style={{ width: 100 }}
            >
                <Option value="IDR">IDR</Option>
                <Option value="USD">USD</Option>
            </Select>
        </>
    );

    const getFormItemStyle = () => {
        return { backgroundColor: '#F0F3F7', padding: '8px', borderRadius: '4px' }

    };

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    const styleStatus = (res) => {
        let status = res.approval_process_id.data.status;

        if (status === "Waiting Approval") {
            setStatusColor({
                background: "#FF9900",
                color: "#FFFFFF"
            })
        } else if (status === "Approved") {
            setStatusColor({
                background: "#1890FF",
                color: "#FFFFFF"
            })
        }
        else if (status === "Closed") {
            setStatusColor({
                background: "#52c41a",
                color: "#FFFFFF"
            })
        }
        else if (status === "Cancel") {
            setStatusColor({
                background: "#F5222D",
                color: "#FFFFFF"
            })
        }
        else {
            setStatusColor({
                background: "#F5222D",
                color: "#FFFFFF"
            })
        }
    }

    const submitApprove = () => {
        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {
                approved()
            },
        );
    }

    const onReject = (values) => {
        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {
                reject(values)
            },
        );
    }

    const cancelCR = (values) => {
        Confirm.show(
            'Important Confirmation',
            'Are you sure ?',
            'Yes',
            'No',
            () => {
                cancel(values)
            },
        );
    }

    const changeConfirmLabel = (nik, status) => {
        if (status === 'Waiting Approval') {
            if (nik === "20560") {
                return 'Waiting Confirm';
            } else {
                return 'Waiting Approval';
            }
        } else {
            if (nik === "20560") {
                return 'Confirmed';
            } else {
                return 'Approved';
            }
        }
    };


    const editDataCR = async () => {
        try {
            Loading.hourglass("Mohon tunggu sedang diproses...");

            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            const responseToken = await instance.acquireTokenSilent(request);
            const accessToken = responseToken.accessToken;

            const headers = HeaderFarms(accessToken);

            let getCurrentData = form.getFieldsValue()
            getCurrentData.record_id = id

            await API.EditCapex(headers, getCurrentData);

            Report.success(
                "Your revision has been recorded.",
                "Thank you",
                "Okay",
                function () {
                    window.location.reload()
                }
            );

        } catch (error) {
            console.error(error);
            Report.warning(
                "Oops... something went wrong.",
                "Sorry, this application is experiencing problems.",
                "Okay"
            );
        } finally {
            Loading.remove();
        }
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then(() => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {

            if (!currentSession) {
                getUser(response.accessToken)
            }

            getUsersByBranch(response.accessToken)
            loadDataFromEpicor(response.accessToken)

            if (id) {
                viewCapex(response.accessToken)
            }

        }).catch((e) => {
            handleLogin(instance)
            console.error(e)
        });
    }

    async function getUser(token) {
        try {
            let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
            setCurrentSession(user)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {

        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }

    }, [isAuthenticated, currentSession])

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Capex Request"
                navigate="/capex/history"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="service_request_form"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    scrollToFirstError={true}
                    autoComplete="off"
                    size='middle'
                    layout='vertical'
                >
                    <div className="container p-3" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                {
                                    detailCapex && (
                                        <>
                                            <div className="col-md-12 mb-2">
                                                <Collapse defaultActiveKey={['1']}>
                                                    <Panel header={"Capex Information"} key="1">
                                                        {detailCapex ? (
                                                            <>
                                                                <div className="row mt-1">
                                                                    <div className="col-md-12">
                                                                        {
                                                                            detailCapex && (
                                                                                <Descriptions size="small" bordered title="Information" className="mb-1">
                                                                                    <Descriptions.Item label="Form ID">
                                                                                        {detailCapex.record_id}
                                                                                    </Descriptions.Item>
                                                                                    <Descriptions.Item className="col-3" label="Capex Type">
                                                                                        {detailCapex.status_request}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {
                                                                            detailCapex && (
                                                                                <Descriptions size="small" bordered className="mb-4">

                                                                                    <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                                                                        {detailCapex.approval_process_id.data.status}
                                                                                    </Descriptions.Item>
                                                                                    <Descriptions.Item className="col-3" label="Created at">
                                                                                        {moment(detailCapex.date).format('LLL')}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                                                                        <Timeline style={{ marginTop: 30 }}>
                                                                            {
                                                                                detailCapex && detailCapex.approval_process_id.data.detail.map(item => {
                                                                                    return (
                                                                                        <Timeline.Item >{item.approver.fullname}
                                                                                            <Alert
                                                                                                message={item.status ? changeConfirmLabel(item.approver.nik, 'Approved')
                                                                                                    : changeConfirmLabel(item.approver.nik, 'Waiting Approval')}
                                                                                                className='mt-1 p-1'
                                                                                                type={item.status ? 'success' : 'warning'}
                                                                                                showIcon
                                                                                            />
                                                                                        </Timeline.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Timeline>
                                                                    </div>
                                                                    {detailCapex && detailCapex.approval_process_id.data.approval_key && (
                                                                        <>
                                                                            <div className="col-md-6 mt-3">
                                                                                <label style={{ fontSize: 15, fontWeight: "bold" }}>Approved Barcode</label>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-12 text-center">
                                                                                        <QRCodeSVG size={130} style={{ padding: 10 }} value={`${window.location.origin}/wf/approved/${detailCapex.approval_process_id.data.approval_key}`} />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {
                                                    detailCapex && detailCapex.approval_process_id.data.comments.length > 0 && (
                                                        <Collapse defaultActiveKey={['1']}>
                                                            <Panel header="Comments" key="1">
                                                                {
                                                                    detailCapex.approval_process_id.data.comments.map(data => {
                                                                        return (
                                                                            <Comment
                                                                                author={<p>{data.uid ? data.uid.fullname : data.approver.fullname}</p>}
                                                                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                                                                content={
                                                                                    <p>
                                                                                        {data.text_plain}
                                                                                    </p>
                                                                                }
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Identity</b>} key="1">
                                        <Row gutter={10}>
                                            <Alert
                                                message={<span style={{ fontSize: '12px' }}>
                                                    The list only displays Fullname for positions Manager and Superintendent.
                                                </span>}
                                                description={<span style={{ fontSize: '10px' }}>
                                                    Please select according to your department
                                                </span>}
                                                type="info"
                                                className="mb-3"
                                            />
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Date"}
                                                    name="date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}

                                                    initialValue={moment()}
                                                >
                                                    <DatePicker style={{ pointerEvents: 'none' }} showTime format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Fullname ( Employee ID )"
                                                    name="fullname"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        className='load-users'
                                                        style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        showSearch
                                                        placeholder="Pilih nama"
                                                        optionFilterProp="children"
                                                        onSelect={
                                                            (event) => {
                                                                form.setFieldsValue({
                                                                    position:
                                                                        (usersBranch.find((employee) => employee.value === event)).position,
                                                                    employee_id:
                                                                        (usersBranch.find((employee) => employee.value === event)).employee_id
                                                                })
                                                            }
                                                        }
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {usersBranch.map(option => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
                                                    name="employee_id"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input hidden />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="Position"
                                                    name="position"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly={!financeStaff} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Departement / Divisi"
                                                    name="department"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        className='load-department'
                                                        showSearch
                                                        placeholder="Select Department"
                                                        optionFilterProp="children"
                                                        style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        onSelect={
                                                            (event) => {
                                                                form.setFieldsValue({
                                                                    department_name:
                                                                        (departmentErp.find((department) => department.SegmentCode === event)).SegmentDesc
                                                                })
                                                            }
                                                        }
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {departmentErp.map(option => (
                                                            <Select.Option key={option.SegmentCode} value={option.SegmentCode}>
                                                                {option.SegmentCode + " - " + option.SegmentDesc}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Departement Description"
                                                    name="department_name"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly={!financeStaff} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel
                                        style={{ background: "#fce8b6" }}
                                        header={
                                            replacementOrNew === "Rental"
                                                ? <b>Rental Requirement</b>
                                                : <b>Asset Replacement</b>
                                        }
                                        key="1"
                                    >
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Status Request"
                                                    name="status_request"
                                                    style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleReplacementOrNew(event)}
                                                >
                                                    <Radio.Group name='replacement_or_new_item_radio_group'>
                                                        <Radio value="New Item">New Item</Radio>
                                                        <Radio value="Replacement">Replacement</Radio>
                                                        <Radio value="Rental">Rental</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                replacementOrNew === "Replacement" && id && (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Existing Asset Code No."
                                                            name="existing_asset_code"
                                                            style={getFormItemStyle('existing_asset_code')}
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                className='load-asset'
                                                                showSearch
                                                                style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {assetErp.map(option => (
                                                                    <Select.Option key={option.AssetNum} value={option.AssetNum}>
                                                                        {option.AssetNum + " - " + option.AssetDescription}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        {
                                            replacementOrNew !== "Rental" && (
                                                <Row gutter={10}>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Asset Status"
                                                            name="asset_status"
                                                            rules={[{ required: true }]}
                                                            onChange={(event) => handleAttachedAsset(event)}
                                                            style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        >
                                                            <Radio.Group name='asset_attached_radio_group'>
                                                                <Radio value="Single Asset">Single Asset</Radio>
                                                                <Radio value="Asset Attached Project">Attached To Project</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        attachedAsset === "Asset Attached Project" && (
                                                            <Col xs={{ span: 23 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                                                                <Form.Item
                                                                    label="Project Code No."
                                                                    name="project_code"
                                                                    rules={[{ required: true }]}
                                                                >
                                                                    <Select
                                                                        style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                        showSearch
                                                                        placeholder="Code No."
                                                                        optionFilterProp="children"
                                                                        onSelect={null}
                                                                        filterOption={(inputValue, option) =>
                                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {projectErp.map(option => (
                                                                            <Select.Option key={option.ProjectID} value={option.ProjectID}>
                                                                                {option.ProjectID + " - " + option.Description}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                            )
                                        }
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel
                                        style={{ background: "#fce8b6" }}
                                        header={
                                            replacementOrNew === "Rental"
                                                ? <b>Rental Description</b>
                                                : <b>Capex Description</b>
                                        }
                                        key="1"
                                    >
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={
                                                        replacementOrNew === "Rental"
                                                            ? <>Machine Name</>
                                                            : <>Capex Name</>
                                                    }
                                                    name="capex_name"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea readOnly={!financeStaff} rows={5} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Brand Reference"
                                                    name="brand_reference"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea readOnly={!financeStaff} rows={5} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Reason For Request"
                                                    name="reason_request"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea readOnly={!financeStaff} rows={5} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Price Info</b>} key="1">
                                        {
                                            replacementOrNew !== "Rental" ? (
                                                <>
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Estimated Price Per Unit"}
                                                                name="estimated_price"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    readOnly={!financeStaff}
                                                                    addonBefore={selectBefore}
                                                                    formatter={(value) =>
                                                                        value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                                                                    }
                                                                    parser={(value) => value.replace(/,/g, '')}
                                                                    onChange={(value) => {
                                                                        const rawValue = parseInt(value, 10) || 0; // Nilai asli tanpa koma
                                                                        if (calculateTotalAmount) {
                                                                            calculateTotalAmount(rawValue); // Panggil fungsi penghitungan
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Unit Qty"}
                                                                name="qty"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    readOnly={!financeStaff}
                                                                    type={"number"}
                                                                    defaultValue={0}
                                                                    onChange={calculateTotalAmount}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Total Amount"}
                                                                name="total_estimated"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    readOnly={true}
                                                                    addonBefore={selectBefore}
                                                                    formatter={(value) =>
                                                                        value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                                                                    }
                                                                    parser={(value) => value.replace(/,/g, '')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Common Operation Useful Life"}
                                                                name="operation_useful_life"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber readOnly={!financeStaff} type={"number"} addonAfter="Month" />
                                                            </Form.Item>
                                                        </Col>

                                                        {
                                                            id && (
                                                                <>
                                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                                        <Form.Item
                                                                            label={"Fiscal Useful Life"}
                                                                            name="fiscal_useful_life"
                                                                            style={getFormItemStyle('fiscal_useful_life')}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Wajib Isi',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber readOnly={!financeStaff} type={"number"} addonAfter="Month" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                                        <Form.Item
                                                                            label={"Residual Value"}
                                                                            name="residual_value"
                                                                            style={getFormItemStyle('residual_value')}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Wajib Isi',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber readOnly={!financeStaff
                                                                            } type={"number"} max="100" addonAfter="Percent %" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    </Row>
                                                </>
                                            ) : (
                                                <>
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>

                                                            <Form.Item
                                                                label={"Estimated Rental Period"}
                                                                name="estimated_rental_period"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    readOnly={!financeStaff}
                                                                    type={"number"}
                                                                    addonAfter={<div style={{ color: "red" }}>Month</div>}
                                                                    onChange={(value) => {
                                                                        const rawValue = parseInt(value, 10) || 0; // Nilai asli tanpa koma
                                                                        if (calculateTotalAmountRental) {
                                                                            calculateTotalAmountRental(rawValue); // Panggil fungsi penghitungan
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Item>

                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 4 }} xl={{ span: 4 }}>
                                                            <Form.Item
                                                                label={"Unit Qty"}
                                                                name="qty_rental_item"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    type={"number"}
                                                                    defaultValue={0}
                                                                    onChange={(value) => {
                                                                        const rawValue = parseInt(value, 10) || 0; // Nilai asli tanpa koma
                                                                        if (calculateTotalAmountRental) {
                                                                            calculateTotalAmountRental(rawValue); // Panggil fungsi penghitungan
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10}>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Estimated Rental Cost"}
                                                                name="estimated_rental_cost"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    addonBefore={selectBefore}
                                                                    addonAfter={<div style={{ color: "red" }}> / Month</div>}
                                                                    formatter={(value) =>
                                                                        value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                                                                    }
                                                                    parser={(value) => value.replace(/,/g, '')}
                                                                    onChange={(value) => {
                                                                        const rawValue = parseInt(value, 10) || 0; // Nilai asli tanpa koma
                                                                        if (calculateTotalAmountRental) {
                                                                            calculateTotalAmountRental(rawValue); // Panggil fungsi penghitungan
                                                                        }
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <div style={{ fontSize: "12px", color: "#BF1212", marginTop: "2px" }}>
                                                                Note Important : Estimate rental cost include all quantity
                                                            </div>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Total Estimated Rental Cost"}
                                                                name="total_estimated_rental_cost"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    readOnly={true}
                                                                    addonBefore={selectBefore}
                                                                    formatter={(value) =>
                                                                        value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                                                                    }
                                                                    parser={(value) => value.replace(/,/g, '')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={
                                        replacementOrNew === "Rental" ?
                                            <b>Rental Custody</b>
                                            : <b>Asset Custody</b>
                                    } key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Asset Operate By"
                                                    name="operate_asset_by"
                                                    rules={[{ required: true }]}
                                                    style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                    onChange={(event) => handleAssetOperateBy(event)}
                                                >
                                                    <Radio.Group name='replacement_or_new_item_radio_group'>
                                                        <Radio value="CPM">CPM</Radio>
                                                        <Radio value="Replacement">External Parties</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                assetOperateBy === "CPM" ? (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Department"
                                                            name="department_operate_asset"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                showSearch
                                                                className='load-department'
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {departmentErp.map(option => (
                                                                    <Select.Option key={option.SegmentCode} value={option.SegmentCode}>
                                                                        {option.SegmentCode + " - " + option.SegmentDesc}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Company Parties"
                                                            name="company_parties_operate"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                showSearch
                                                                className='load-vendor'
                                                                placeholder="Company Code."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {vendorErp.map(option => (
                                                                    <Select.Option key={option.VendorID} value={option.VendorID}>
                                                                        {option.VendorID + " - " + option.Name}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Asset Maintenance By"
                                                    name="asset_maintenance_by"
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleAssetMaintenanceBy(event)}
                                                    style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                >
                                                    <Radio.Group name='asset_maintenance_radio_group'>
                                                        <Radio value="CPM">CPM</Radio>
                                                        <Radio value="Replacement">External Parties</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                assetMaintenanceBy === "CPM" ? (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Department"
                                                            name="department_maintenance_asset"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                showSearch
                                                                className='load-department'
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {departmentErp.map(option => (
                                                                    <Select.Option key={option.SegmentCode} value={option.SegmentCode}>
                                                                        {option.SegmentCode + " - " + option.SegmentDesc}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Company Parties"
                                                            name="company_parties_maintenance"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                                showSearch
                                                                className='load-vendor'
                                                                placeholder="Company Code."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {vendorErp.map(option => (
                                                                    <Select.Option key={option.VendorID} value={option.VendorID}>
                                                                        {option.VendorID + " - " + option.Name}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Utilization Area</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Area Number Code"
                                                    name="area_number_code"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        showSearch
                                                        className='load-area'
                                                        placeholder="Area Code"
                                                        optionFilterProp="children"
                                                        onSelect={(event) => {
                                                            form.setFieldsValue({
                                                                area_description_name:
                                                                    (areaErp.find((area) => area.SegmentCode === event)).SegmentDesc
                                                            })
                                                        }}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {areaErp.map(option => (
                                                            <Select.Option key={option.SegmentCode} value={option.SegmentCode}>
                                                                {option.SegmentCode + " - " + option.SegmentDesc}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Area Description Name"
                                                    name="area_description_name"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly={!financeStaff} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                {
                                    id && (
                                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                            <Panel style={{ background: "#fce8b6" }} header={<b>Management Accounting</b>} key="1">
                                                <Row gutter={10}>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Asset Category"
                                                            name="asset_category"
                                                            className='.load-assetg'
                                                            style={getFormItemStyle('asset_category')}
                                                            rules={[{ required: true, message: 'Please select an asset category!' }]}
                                                            styles={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        >
                                                            <Radio.Group>
                                                                {categoryAssetErp.map((category) => (
                                                                    <Radio style={{ fontSize: 10 }} key={category.GroupCode} className="mt-1" value={category.GroupCode}>
                                                                        {category.GroupCode} - {category.Description}
                                                                    </Radio>
                                                                ))}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Fixed Asset Code"
                                                            name="fixed_asset_code"
                                                            style={getFormItemStyle('asset_category')}
                                                            rules={[{ required: false }]}
                                                        >
                                                            <Input readOnly={!financeStaff} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    )}
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={
                                        replacementOrNew === "Rental" ?
                                            <b>Management Accounting for Rental</b>
                                            : <b>Activity Cost Code For Depreciation & Amortisation Expense Allocation</b>
                                    } key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Activity Number Code"
                                                    name="activity_number_code"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ pointerEvents: financeStaff ? 'auto' : 'none' }}
                                                        showSearch
                                                        className='load-activity'
                                                        placeholder="Activity Code"
                                                        optionFilterProp="children"
                                                        onSelect={(event) => {
                                                            form.setFieldsValue({
                                                                activity_description_name:
                                                                    (activityErp.find((activity) => activity.SegmentCode === event)).SegmentDesc
                                                            })
                                                        }}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.join("").toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {activityErp.map(option => (
                                                            <Select.Option key={option.SegmentCode} value={option.SegmentCode}>
                                                                {option.SegmentCode} - {option.SegmentDesc}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Activity Description Name"
                                                    name="activity_description_name"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input readOnly={!financeStaff} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    {

                        !id && (
                            <Row className='justify-content-center'>
                                <Col>
                                    <Form.Item>
                                        <Button size='large' type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    {
                        detailCapex &&
                        (detailCapex.approval_process_id.data.status !== 'Cancel' ||
                            detailCapex.approval_process_id.data.status !== 'Reject') && (

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Affix offsetBottom={20}>
                                        <Form.Item className="text-center">
                                            <div className="d-flex flex-wrap justify-content-center gap-2">
                                                {
                                                    detailCapex.created_by === currentSession.nik && (
                                                        <Button
                                                            type="danger"
                                                            onClick={showModal}
                                                            size="middle"
                                                            icon={<CloseCircleOutlined />}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    )
                                                }

                                                {
                                                    detailCapex.approval_process_id.data.detail.filter(a => a.approver_id === currentSession.nik).length > 0 ||
                                                        detailCapex.approval_process_id.data.form_id.form_setting[0].pic.filter(employeeId => employeeId === currentSession.nik).length > 0 ? (
                                                        <>
                                                            <Button
                                                                type="primary"
                                                                onClick={submitApprove}
                                                                size="middle"
                                                                icon={<CheckCircleOutlined />}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                type="danger"
                                                                size="middle"
                                                                onClick={showModalReject}
                                                                icon={<ExclamationCircleOutlined />}
                                                            >
                                                                Reject
                                                            </Button>
                                                            <Button
                                                                type="danger"
                                                                size="middle"
                                                                htmlType="submit"
                                                                icon={<EditFilled />}
                                                            >
                                                                Revision
                                                            </Button>
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                        </Form.Item>
                                    </Affix>

                                </div>
                            </div>
                        )
                    }

                </Form>
            </div >
            <Modal
                title="Write the reason cancel"
                footer={null}
                visible={isModalVisible}
                onOk={null}
                onCancel={handleCancel}
            >
                <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={cancelCR}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        label=""
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: "Please input your reason",
                            },
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            style={{ height: "150px", width: "100%" }}
                            minLength={10}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mt-3"
                        wrapperCol={{
                            xs: {
                                offset: 8, span: 18
                            },
                            sm: {
                                offset: 7, span: 18
                            },
                            lg: { offset: 8, span: 18 }
                        }}

                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Write the reason reject"
                footer={null}
                visible={isModalVisibleReject}
                onOk={null}
                onCancel={handleModalReject}
            >
                <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onReject}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        label=""
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Message!",
                            },
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            style={{ height: "150px", width: "100%" }}
                            minLength={10}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mt-3"
                        wrapperCol={{
                            xs: {
                                offset: 8, span: 18
                            },
                            sm: {
                                offset: 7, span: 18
                            },
                            lg: { offset: 8, span: 18 }
                        }}

                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2023 Information Technology</Layout.Footer>
        </>
    );
}
export default CreateCapex