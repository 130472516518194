import {
  PageHeader,
  Result,
  Button,
  message,
  Form,
  Row,
  Col,
  Input,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

import { initializeVerification } from "../../redux/action";
import { loginRequest } from "../../config/api-config";
import HeaderFarms from "../../utils/header_farms";

import { Confirm, Report } from "notiflix";
import Footer from "../../atoms/footer";
import API from "../../services";
import id_png from "./id.png";
import "./style.css";

function CompletedUserData() {
  let navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const [allUserFARMS, setAllUserFARMS] = useState([]);
  const [allUserAD, setAllUserAD] = useState([]);

  const [form] = Form.useForm();
  const { Option } = Select;

  const getUsersAutoComplete = async () => {
    API.getUsers(HeaderFarms(null))
      .then(async (res) => {
        setAllUserFARMS(res.data);

        const request = {
          ...loginRequest,
          account: accounts[0],
        };

        const getToken = await instance.acquireTokenSilent(request);
        let getUser = res.data.find(
          (a) =>
            a.fullname.toUpperCase() ===
            getToken.account.name.toLocaleUpperCase()
        );

        console.log("Token Account Name:", getToken?.account?.name);
        console.log("User  Data:", res.data);
        console.log("Found User:", getUser);
        if (getUser && getUser.fullname) {
          form.setFieldsValue({ fullName: getUser.fullname, id: getUser._id });
          getUser365toMicrosoftGraph(getUser.fullname);
        }
      })
      .catch((err) => {
        console.log(err);
        message.warning("Terjadi Kesalahan saat load data user");
      });
  };

  const setValueFullname = (a) => {
    let UserID = allUserFARMS.find((b) => b.fullname === a);

    form.setFieldsValue({ fullName: a, email: null, id: UserID._id });
    setAllUserAD([]);

    getUser365toMicrosoftGraph(a);
  };

  const getUser365toMicrosoftGraph = async (data) => {
    try {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      const getToken = await instance.acquireTokenSilent(request);

      const searchUrl = `https://graph.microsoft.com/v1.0/users?$search="displayName:${data}"&$orderby=displayName&$count=true`;

      const searchResponse = await fetch(searchUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken.accessToken}`,
          ConsistencyLevel: "eventual",
        },
      });

      const searchData = await searchResponse.json();
      const totalResults = searchData?.["@odata.count"] || 0;

      if (totalResults > 0) {
        const allUsers = searchData.value.map((user) => ({
          id: user.id,
          name: user.displayName,
          email: user.mail || user.userPrincipalName,
        }));

        return setAllUserAD(allUsers);
      } else {
        const fallbackUrl = `https://graph.microsoft.com/v1.0/users?$top=999`;

        const fallbackResponse = await fetch(fallbackUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken.accessToken}`,
            ConsistencyLevel: "eventual",
          },
        });

        const fallbackData = await fallbackResponse.json();
        const allUsers = fallbackData.value.map((user) => ({
          id: user.id,
          name: user.displayName,
          email: user.mail || user.userPrincipalName,
        }));
        return setAllUserAD(allUsers);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function onFinish(data) {
    Confirm.show(
      "Important Confirmation",
      "Email and name is correct ?",
      "Yes",
      "No",
      async () => {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };

        const getToken = await instance.acquireTokenSilent(request);

        API.completeUserData(HeaderFarms(getToken.accessToken), data)
          .then(async (res) => {
            navigate("/");
          })
          .catch((err) => {
            console.error(err);
            Report.warning(
              "Oops.. something wrong",
              "Sorry, this application is experiencing problems",
              "Okay"
            );
          });
      }
    );
  }

  function Forms() {
    return (
      <>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => message.warning("Mohon lengkapi formulir.")}
        >
          <Row justify="center">
            <Col xs={24} sm={20} md={16} lg={12} xl={10}>
              <Form.Item
                label="Full Name"
                name="fullName"
                rules={[{ required: true, message: "Please input fullname" }]}
              >
                <Select
                  style={{ width: "100%", textAlign: "start" }}
                  onSelect={(a) => setValueFullname(a)}
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  {allUserFARMS.map((suggestion) => (
                    <Option key={suggestion._id} value={suggestion.fullname}>
                      {suggestion.fullname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input email" }]}
              >
                <Select
                  style={{ width: "100%", textAlign: "start" }}
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.value &&
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  {allUserAD.map((suggestion) => (
                    <Option key={suggestion.id} value={suggestion.email}>
                      {suggestion.email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Hidden ID Input */}
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <Form.Item>
                <Button type="primary" size="middle" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  useEffect(() => {
    getUsersAutoComplete();
  }, []);

  return (
    <>
      <div className="container" style={{ minHeight: "700px" }}>
        <div className="row">
          <div className="col-md-12">
            <PageHeader ghost={true} title="User Register" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Result
              status="info"
              icon={<img src={id_png} width="120px" alt="" />}
              title={
                <>
                  Silahkan masukan <strong>Nama</strong> dan{" "}
                  <strong>Alamat Email</strong> anda
                </>
              }
              extra={
                <>
                  <br />
                  <Forms />
                </>
              }
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  // initializeTicket: state.initializeTicket,
});

const reduxDispatch = (dispatch) => ({
  setInitializeVerification: (data) => dispatch(initializeVerification(data)),
});

export default connect(reduxState, reduxDispatch)(CompletedUserData);
