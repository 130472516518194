import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Affix,
  Button,
  Spin,
  DatePicker,
  Select,
  Statistic,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMagnifyingGlass,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Report } from "notiflix";
import moment from "moment";

import "./styles.css";

import { callMsGraph } from "../../../utils/graph";
import { loginRequest } from "../../../config/api-config";

import HeaderFarms from "../../../utils/header_farms";
import API from "../../../services";

function FuelDeliveryHistory() {
  let navigate = useNavigate();
  const { Option } = Select;

  const { instance, accounts } = useMsal();
  const [historyDelivery, setHistoryDelivery] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [totalFuel, setTotalFuel] = useState(0);
  const [filter, setFilter] = useState(null);
  const [shift, setShift] = useState(null);

  const [FT] = useState([
    "TS-9657-MMS",
    "FT-002",
    "FT-003",
    "FT-1003-CPM",
    "Tangki A",
    "Tangki B",
    "TS-9658",
  ]);

  const [fuelShift] = useState(["SIANG", "MALAM"]);

  function handleStartDateChange(date) {
    setStartDate(date);
    sessionStorage.setItem("startDate", date);
  }

  function handleEndDateChange(date) {
    setEndDate(date);
    sessionStorage.setItem("endDate", date);
  }

  function handleLogin(instance) {
    instance
      .loginRedirect(loginRequest)
      .then((res) => {})
      .catch((e) => {
        console.error(e);
      });
  }

  async function getHistory(accessToken, startDate, endDate) {
    try {
      const res = await API.HistoryRequestDelivery(HeaderFarms(accessToken), {
        startDate: startDate,
        endDate: endDate,
      });
      setHistoryDelivery(res.data || []);
      totalFuelCount(null, res.data || []);
    } catch (error) {
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
      Loading.remove();
    } finally {
      setLoading(false);
    }
  }

  function search() {
    setLoading(true);
    handleFilterByDate();
  }

  function handleChange(value) {
    setFilter(value);
    totalFuelCount(value, shift, historyDelivery);
  }

  function handleChangeFuelShift(value) {
    setShift(value);
    totalFuelCount(value, filter, historyDelivery);
  }

  function totalFuelCount(filterFromSelect, filterFromShift, requestsFuel) {
    // periksa apakah data berupa array
    if (!Array.isArray(requestsFuel)) {
      return;
    }
    let total = 0;
    requestsFuel.forEach((request) => {
      const filterTruck =
        !filterFromSelect || request.fuel_truck === filterFromSelect;
      const filterShift = !filterFromShift || request.shift === filterFromShift;

      if (filterTruck && filterShift) {
        total += request.issued_total || 0;
      }
    });

    setTotalFuel(total);
    return total;
  }

  function handleFilterByDate() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        callMsGraph(responseToken.accessToken).then((_) => {
          // Convert start and end dates to JavaScript Date objects
          let startDateFormat = new Date(startDate);
          let endDateFormat = new Date(endDate);

          // Retrieve startDate and endDate from sessionStorage
          const savedEndDate = sessionStorage.getItem("endDate");
          const savedStartDate = sessionStorage.getItem("startDate");

          // Set startDate and endDate if they exist in sessionStorage
          if (savedStartDate) {
            startDateFormat = new Date(savedStartDate);
            setStartDate(savedStartDate);
          }
          if (savedEndDate) {
            endDateFormat = new Date(savedEndDate);
            setEndDate(savedEndDate);
          }

          // Set the time part of the dates to 00:00:00 for start date and 23:59:59 for end date
          startDateFormat.setHours(0, 0, 0, 0);
          endDateFormat.setHours(23, 59, 59, 999);

          getHistory(responseToken.accessToken, startDateFormat, endDateFormat);
        });
      })
      .catch((e) => {
        handleLogin(instance);
      });
  }

  const getAllDeliveryFuelTruck = () => {
    for (const truck of FT) {
      API.DeliveryLatest(HeaderFarms(), truck);
    }
  };

  function goDetail(id) {
    navigate(`/fms/delivery/view/${id}`);
  }

  useEffect(() => {
    search();
    getAllDeliveryFuelTruck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (historyDelivery.length > 0) {
      totalFuelCount(filter, shift, historyDelivery);
    }
  }, [historyDelivery, filter, shift]);

  return (
    <Fragment>
      <div className="container-fluid p-0 mt-3">
        <div className="row">
          <div className="col">
            <Affix offsetTop={10}>
              <div className="d-flex justify-content-start">
                <Button
                  onClick={() => navigate("/fms/delivery/create")}
                  size="middle"
                  className="btn-color-gradient"
                >
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    style={{ marginRight: "4px" }}
                  />
                  New Delivery
                </Button>
              </div>
            </Affix>
          </div>
          <div className="col" style={{ marginTop: -10 }}>
            <Statistic title="Fuel Issued (LT)" value={totalFuel} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="d-flex flex-column flex-md-row gap-3">
            <div className="col-lg-3">
              <DatePicker
                onChange={handleStartDateChange}
                placeholder="Select Date"
                value={moment(startDate)}
                style={{ width: "100%" }}
                allowClear={false}
              />
            </div>
            <div className="col-lg-3">
              <DatePicker
                onChange={handleEndDateChange}
                placeholder="End Date"
                value={moment(endDate)}
                style={{ width: "100%" }}
                allowClear={false}
              />
            </div>
            <div className="col-lg-3">
              <Button
                className="btn-color-gradient text-center"
                onClick={search}
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#ffffff", marginRight: "3px" }}
                />
                Cari
              </Button>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="d-flex gap-3">
            <div className="col-6 col-md-3">
              <label>Truck</label>
              <Select
                style={{
                  width: "100%",
                }}
                size="middle"
                value={filter}
                onChange={handleChange}
                placeholder="Pilih FT"
              >
                {FT.map((truck) => {
                  return <Option value={truck}>{truck}</Option>;
                })}
              </Select>
            </div>
            <div className="col-6 col-md-3">
              <label>Shift :</label>
              <Select
                style={{ width: "100%" }}
                value={shift}
                onChange={handleChangeFuelShift}
                size="middle"
                placeholder="Select Shift"
              >
                {fuelShift.map((shift) => {
                  return <Option value={shift}>{shift}</Option>;
                })}
              </Select>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-10 offset-md-1">
            {loading && (
              <div
                className="fs-6 text-center mt-5"
                style={{ color: "#B7B7B7" }}
              >
                <Spin size="large" />
              </div>
            )}
            {historyDelivery.length > 0 && (
              <>
                {historyDelivery
                  .filter((data) => {
                    const filterTruck = !filter || data.fuel_truck === filter;
                    const filterShift = !shift || data.shift === shift;
                    return filterTruck && filterShift;
                  })
                  .map((data) => {
                    return (
                      <Fragment key={data.id_record}>
                        <Badge.Ribbon text={data.issued_total + " LT"}>
                          <div
                            className="list-style p-2 px-3"
                            onClick={() => goDetail(data.record_id)}
                          >
                            <div className="title d-flex justify-content-between">
                              <div className="fs-7 mb-0">{data.fuel_truck}</div>
                              <div
                                className="fs-7 mb-0"
                                style={{ color: "#B7B7B7", fontSize: 11 }}
                              >
                                Sisa: {data.remaining_fuel_period} -
                                Pengeluaran: {data.issued_period_before} -{" "}
                                <span
                                  style={
                                    data.status_approval.status !== "Approved"
                                      ? {
                                          background: "#E0710F",
                                          padding: 3,
                                          color: "#FFFFFF",
                                        }
                                      : {
                                          background: "#1890FF",
                                          padding: 3,
                                          color: "#FFFFFF",
                                        }
                                  }
                                >
                                  {data.status_approval.status}
                                </span>
                              </div>
                            </div>
                            <div className="title mt-2">
                              <p style={{ color: "#B7B7B7", fontSize: 11 }}>
                                {data.operator?.fullname}
                              </p>
                            </div>
                            <div className="hour d-flex justify-content-between">
                              <p className="fs-7">
                                <span
                                  style={{
                                    color: "#1890FF",
                                    fontSize: 11,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.fuel_truck} - {data.shift}
                                </span>{" "}
                                -{" "}
                                <span
                                  style={{ color: "#B7B7B7", fontSize: 11 }}
                                >
                                  {" "}
                                  {data.created_by} -{" "}
                                  {moment(data.date).format("YYYY-MM-DD")}{" "}
                                  {moment(data.time).format("HH:mm")}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Badge.Ribbon>
                      </Fragment>
                    );
                  })}
              </>
            )}

            {historyDelivery.length === 0 && (
              <div
                className="fs-6 text-center mt-5"
                style={{ color: "#B7B7B7" }}
              >
                No more data
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FuelDeliveryHistory;
