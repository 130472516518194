import React, { useEffect, useRef, useState } from "react";

import {
  Layout,
  Collapse,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Radio,
  InputNumber,
  Button,
  AutoComplete,
  Modal,
  Alert,
  Upload,
} from "antd";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Navbar from "../../../atoms/navbar";

import API from "../../../services";
import HeaderFarms from "../../../utils/header_farms";
import { loginRequest } from "../../../config/api-config";
import { checkNetworkType } from "../../../utils/check_network";
import { Confirm, Loading, Notify, Report } from "notiflix";
import { Detector } from "react-detect-offline";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

import PinInput from "react-pin-input";
import bcrypt from "bcryptjs";

function CreateRequestFuel() {
  const [form] = Form.useForm();
  const { instance, accounts } = useMsal();

  const { Panel } = Collapse;
  const pinInputRef = useRef(null);
  const operatorInputRef = useRef(null);
  const [Location, setLocation] = useState([]);
  const [Operators, setOperators] = useState([]);
  const [BackDate, setBackDate] = useState(false);
  const [PinModal, setPinModal] = useState(false);
  const [Equipments, setEquipments] = useState([]);
  const [Organization, setOrganization] = useState([]);
  const [fileListOperator, setFileListOperator] = useState([]);
  const [EquipmentsActivity, setEquipmentActivity] = useState([]);
  const [overrideOperator, setOverrideOperator] = useState("Operator");
  const [currentOperator, setCurrentOperator] = useState(null);
  const [fileListVehicle, setFileListVehicle] = useState([]);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileListKMmeter, setFileListKMmeter] = useState([]);
  const [fileListMeter, setFileListMeter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [token, setToken] = useState(null);
  const [isOnline, setIsOnline] = useState(true);

  const handleAmountSubmittedChange = (value) => {
    form.setFieldsValue({
      amount_issued: value,
    });
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Limit to only one file
    fileList = fileList.slice(-1);
    // Update state
    setFileList(fileList);
  };

  const handleUploadKmMeter = (info) => {
    let fileList = [...info.fileList];
    // Limit to only one file
    fileList = fileList.slice(-1);
    // Update state
    setFileListKMmeter(fileList);
  };

  const handleUploadMeter = (info) => {
    let fileListMeter = [...info.fileList];
    // Limit to only one file
    fileListMeter = fileListMeter.slice(-1);
    // Update state
    setFileListMeter(fileListMeter);
  };

  const handleUploadOperator = (info) => {
    let fileListOperator = [...info.fileList];
    // Limit to only one file
    fileListOperator = fileListOperator.slice(-1);
    // Update state
    setFileListOperator(fileListOperator);
  };

  const handleUploadVehicle = (info) => {
    let fileListVehicle = [...info.fileList];
    // Limit to only one file
    fileListVehicle = fileListVehicle.slice(-1);
    // Update state
    setFileListVehicle(fileListVehicle);
  };

  const getArea = async (token) => {
    try {
      const res = await API.JobLocation(HeaderFarms(token), null);
      const uniqueLocations = [
        ...new Set(res.data.map((area) => area.location)),
      ];
      setLocation(uniqueLocations);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  };

  const getEquipments = async (token) => {
    try {
      const res = await API.Equipments(HeaderFarms(token), null);
      setEquipments(res.data);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  };

  const getOperator = async (token) => {
    try {
      const res = await API.Operators(HeaderFarms(token), null);
      setOperators(res.data);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  };

  const getOrganization = async (token) => {
    try {
      const res = await API.Organization(HeaderFarms(token), null);
      setOrganization(res.data);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  };

  const getEquipmentActivity = (token) => {
    API.EquipmentActivity(HeaderFarms(token), null)
      .then((res) => {
        setEquipmentActivity(res.data);
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  const showModal = () => {
    setPinModal(true);
  };

  const closePinModal = () => {
    setPinModal(false);
  };

  const handleChangeOperator = (data) => {
    // // Set operator_id to null and blur operator input
    // form.setFieldsValue({ operator_id: null });
    // operatorInputRef.current.blur();

    // // Find the current operator
    const currentOperator = Operators.find(
      (operator) => operator.employee_id === data
    );
    setCurrentOperator(currentOperator);

    // // Show the modal and focus on pin input after a short delay
    // showModal(true);
    // setTimeout(() => pinInputRef.current.focus(), 100);
  };

  const validationPin = async (value) => {
    try {
      // Compare the PIN entered with the current operator's password
      const match = await bcrypt.compare(value, currentOperator.password);
      setPasswordStatus(!match); // Set password status based on the match result

      // Clear the PIN input regardless of match status
      pinInputRef.current.clear();

      // Set operator_id and close the PIN modal if the PIN is correct
      form.setFieldsValue({
        operator_id: match ? currentOperator.employee_id : null,
      });

      if (match) {
        closePinModal();
      }
    } catch (error) {
      console.error("Error while verifying password:", error);
    }
  };

  const ClearForm = () => {
    form.setFieldsValue({
      time: moment(),
      hull_number: null,
      equipment_name: null,
      operator_id: null,
      category: null,
      kilometer: null,
      hourmeter: null,
      amount_submitted: null,
      amount_issued: null,
      equipment_owner: null,
      flow_attachment: null,
      vehicle_meter_attachment: null,
      attachment_operator: null,
      override: "Operator",
      segment_code: null,
      vehicle_attachment: null,
      vehicle_kmeter_attachment: null,
    });

    setOverrideOperator("Operator");
    setPasswordStatus(false);
    setFileListOperator([]);
    setFileListVehicle([]);
    setFileListMeter([]);
    setFileList([]);
    setFileListKMmeter([]);
  };

  const reportSuccessOnline = () => {
    Report.success(
      `Has been recorded.`,
      "Berhasil di tersimpan",
      "Ok",
      function () {
        ClearForm();
      }
    );
  };

  const reportSuccessOffline = () => {
    Report.success(
      `OFFLINE : Has been recorded on local.`,
      "Berhasil di tersimpan di Lokal anda",
      "Ok",
      function () {
        ClearForm();
      }
    );
  };

  const parsingNullToZero = (data) => {
    if (!data.kilometer) data.kilometer = 0;
    if (!data.hourmeter) data.hourmeter = 0;
  };

  const saveRequest = async (data) => {
    try {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      const responseToken = await instance.acquireTokenSilent(request);

      const formData = new FormData();

      // Append file attachments to FormData
      if (data.flow_attachment?.fileList[0]) {
        formData.append(
          "flow_attachment",
          data.flow_attachment.fileList[0].originFileObj
        );
      }
      if (data.vehicle_meter_attachment?.fileList[0]) {
        formData.append(
          "vehicle_meter_attachment",
          data.vehicle_meter_attachment.fileList[0].originFileObj
        );
      }
      if (data.attachment_operator?.fileList[0]) {
        formData.append(
          "attachment_operator",
          data.attachment_operator.fileList[0].originFileObj
        );
      }
      if (data.vehicle_attachment?.fileList[0]) {
        formData.append(
          "vehicle_attachment",
          data.vehicle_attachment.fileList[0].originFileObj
        );
      }
      if (data.vehicle_kmeter_attachment?.fileList[0]) {
        formData.append(
          "vehicle_kmeter_attachment",
          data.vehicle_kmeter_attachment.fileList[0].originFileObj
        );
      }

      // Separate other data and convert it to JSON
      const jsonData = {};
      Object.keys(data).forEach((key) => {
        if (
          key !== "flow_attachment" &&
          key !== "vehicle_meter_attachment" &&
          key !== "attachment_operator" &&
          key !== "vehicle_attachment" &&
          key !== "vehicle_kmeter_attachment"
        ) {
          jsonData[key] = data[key];
        }
      });

      // Convert JSON data to string and append it to FormData
      formData.append("json_data", JSON.stringify(jsonData));

      await API.CreateRequestFuel(
        HeaderFarms(responseToken.accessToken),
        formData
      );

      reportSuccessOnline();
    } catch (err) {
      console.error("saveRequest error:", err.response.status);

      if (err.code === "ECONNABORTED") {
        reportSuccessOffline();
      } else {
        if (!isOnline) {
          if (err.response.status === 500) {
            Report.warning(
              `Gagal menyimpan data ke lokal`,
              JSON.stringify(err.response.data),
              "Okay"
            );
          } else if (err.response.status === 503) {
            console.error("Network error detected: offline or network issues");
            reportSuccessOffline();
          }
        } else {
          console.error("Non-network error occurred");
          // Optionally, show an error message to the user here
          Report.warning(
            "Ops.. Ada kesalahan pada sistem",
            "Maaf transaksi anda gagal, mohon coba lagi nanti.",
            "Okay"
          );
        }
      }
    } finally {
      Loading.remove();
      setIsSubmitting(false);
    }
  };

  const onFinish = async (data) => {
    //console.log(data);

    if (isSubmitting) return;

    setIsSubmitting(true);
    parsingNullToZero(data);
    Confirm.show(
      "Important Confirmation",
      "Are you sure?",
      "Yes",
      "No",
      async () => {
        try {
          Loading.hourglass("Mohon tunggu sedang di proses.. ");
          await saveRequest(data);
        } catch (error) {
          console.error("onFinish error:", error);
        } finally {
          Loading.remove();
          setIsSubmitting(false);
        }
      },
      () => {
        setIsSubmitting(false);
      }
    );
  };

  const handleOverrideChange = (event) => {
    setOverrideOperator(event.target.value);
  };

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((responseToken) => {
        const token = responseToken.accessToken;
        getEquipmentActivity(token);
        getOrganization(token);
        getEquipments(token);
        getOperator(token);
        setToken(token);
        getArea(token);
      })
      .catch((e) => {});
  }

  const refreshListData = async () => {
    setLoading(true);

    try {
      await Promise.all([
        getEquipmentActivity(token),
        getOrganization(token),
        getEquipments(token),
        getOperator(token),
        getArea(token),
      ]);
    } catch (error) {
      Notify.failure("Gagal memperbarui data.");
    } finally {
      Notify.success("Berhasil memperbarui data");
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    message.warning("Mohon lengkapi formulir.");
  };

  const handleFillEquipment = (equipmentSelected) => {
    let equipmentFilter = Equipments.find(
      (equipment) => equipment.identity_number === equipmentSelected
    );
    form.setFieldsValue(equipmentFilter);
  };

  useEffect(() => {
    if (accounts.length > 0) {
      RequestToken();

      form.setFieldsValue({
        fuel_type: "Solar",
        override: "Operator",
      });

      if (PinModal && pinInputRef.current) {
        pinInputRef.current.focus();
      }
    }

    // Patent Redirect for domain spesific
    const currentURL = window.location.href;
    const url = new URL(currentURL);

    switch (url.hostname) {
      case "backdaterf.citrapalu.net":
      case "backdatedr.citrapalu.net":
        setBackDate(true);
      default:
    }

    checkNetworkType();

    if ("serviceWorker" in navigator) {
      const messageHandler = async (event) => {
        try {
          if (event.data && event.data.msg) {
            if (event.data.msg === "PROGRESS_SYNC") {
              Notify.success(
                "Sedang Mengirim ke server. Jangan tinggalkan halaman ini",
                {
                  timeout: 5000,
                }
              );
            }

            if (event.data.msg === "OK_SYNC") {
              Notify.success(`${event.data.detail}`, {
                timeout: 3000,
              });
            }

            if (event.data.msg === "NOOK_SYNC") {
              Notify.failure("NO_OK_SYNC");

              Report.success(
                `Gagal terkirim ke server ..`,
                "Mohon mohon tunggu beberapa saat. ",
                "Ok"
              );
            }

            if (event.data.msg === "COMPLETE_SYNC") {
              setTimeout(() => {
                Report.success(
                  `Berhasil terkirim ke server ..`,
                  "Mohon mohon lakukan pengecekan transaksi. ",
                  "Ok"
                );
              }, 2000);
            }
          }
        } catch (error) {
          console.error("Error processing service worker message:", error);
        }
      };

      navigator.serviceWorker.addEventListener("message", messageHandler);

      return () => {
        navigator.serviceWorker.removeEventListener("message", messageHandler);
      };
    }
  }, [PinModal]);

  return (
    <>
      <Detector
        render={({ online }) => {
          setIsOnline(online);
          return null;
        }}
      />
      <Navbar
        title="Create"
        back={true}
        subtitle="Fuel System"
        navigate="/fms/menu"
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="fuel_request"
          initialValues={{
            remember: true,
          }}
          encType="multipart/form-data"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="vertical"
        >
          <div className="p-2" style={{ alignSelf: "center" }}>
            <Modal
              title="Masukan PIN Operator"
              footer={null}
              centered
              open={PinModal}
              onCancel={closePinModal}
              className="p-2"
              transitionName=""
            >
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret={false}
                    type="numeric"
                    style={{
                      textAlign: "center",
                      marginBottom: 20,
                      fontSize: 30,
                      fontWeight: "bold",
                    }}
                    inputStyle={{
                      borderColor: "#1890FF",
                      borderRadius: 10,
                      borderWidth: 2,
                    }}
                    inputFocusStyle={{ borderColor: "blue" }}
                    onComplete={(value, index) => {
                      validationPin(value);
                    }}
                    ref={pinInputRef}
                    autoSelect={false}
                    onKeyUp={() => setPasswordStatus(false)}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                  {passwordStatus && (
                    <Alert message="PIN Salah" type="error" showIcon />
                  )}
                </Col>
              </Row>
            </Modal>

            <div className="row">
              <div className="col-md-12">
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={
                      <b style={{ fontSize: "18px", fontWeight: "bold" }}>
                        Request Fuel
                      </b>
                    }
                    key="2"
                  >
                    {BackDate && (
                      <Alert
                        className="mb-3 mt-2"
                        message="Mode Backdate Aktif, Mohon di input tanggal yang sesuai aktual !"
                        type="warning"
                        showIcon
                      />
                    )}
                    <Row>
                      {token && (
                        <Col
                          className="mb-4"
                          xs={{ span: 24 }}
                          sm={{ span: 10 }}
                          xl={{ span: 10 }}
                        >
                          <Alert
                            className="mb-3 mt-2"
                            message="Klik tombol ini Jika list operator, kendaraan, atau lokasi belum ada / update setelah pembaruan."
                            type="info"
                            showIcon
                          />
                          <Button
                            loading={loading}
                            type="primary"
                            onClick={refreshListData}
                            size="middle"
                          >
                            Ambil Data list
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 3 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Date
                            </span>
                          }
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please input date",
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <DatePicker
                            style={{
                              pointerEvents: BackDate ? "auto" : "none",
                            }}
                            showTime
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 12 }} sm={{ span: 8 }} xl={{ span: 3 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Time
                            </span>
                          }
                          name="time"
                          rules={[
                            {
                              required: true,
                              message: "Please input time",
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <TimePicker
                            style={{
                              pointerEvents: BackDate ? "auto" : "none",
                            }}
                            showNow={false}
                            format="HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 8 }} xl={{ span: 3 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Shift
                            </span>
                          }
                          name="shift"
                          rules={[
                            {
                              required: true,
                              message: "Please select shift",
                            },
                          ]}
                        >
                          <AutoComplete
                            placeholder="Select"
                            allowClear
                            options={["SIANG", "MALAM"].map((item) => ({
                              value: item,
                            }))}
                            filterOption={(inputValue, option) => {
                              return (
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Fuel Truck / Distribution
                            </span>
                          }
                          name="fuel_truck"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <AutoComplete
                            placeholder="Select"
                            allowClear
                            options={[
                              // "TS-9657-MMS",
                              // "FT-002",
                              // "FT-003",
                              "FT-1003-CPM",
                              "Tangki A",
                              "Tangki B",
                              "TS-9658",
                            ].map((item) => ({ value: item }))}
                            filterOption={(inputValue, option) => {
                              return (
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20} justify="space-between" align="middle">
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        xl={{ span: 12 }}
                      >
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Lokasi Kerja
                            </span>
                          }
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Pilih Lokasi"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              Location.length > 0 ? (
                                <CheckCircleOutlined
                                  style={{ color: "green", fontSize: 18 }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{ color: "red", fontSize: 18 }}
                                />
                              )
                            }
                          >
                            {Location.map((item, index) => (
                              <Select.Option key={index} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Nomor Lambung
                            </span>
                          }
                          name="hull_number"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            onChange={handleFillEquipment}
                            suffixIcon={
                              Location.length > 0 ? (
                                <CheckCircleOutlined
                                  style={{ color: "green", fontSize: 18 }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{ color: "red", fontSize: 18 }}
                                />
                              )
                            }
                          >
                            {Equipments.map((item) => (
                              <Select.Option
                                key={item.identity_number}
                                value={item.identity_number}
                              >
                                {item.identity_number}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Nama alat berat
                            </span>
                          }
                          name="equipment_name"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Owner
                            </span>
                          }
                          name="equipment_owner"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item
                          style={{ display: "none" }}
                          label=""
                          name="category"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input hidden />
                        </Form.Item>
                      </Col>
                      <Form.Item
                        label={
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Lampiran Kendaraan
                          </span>
                        }
                        name="vehicle_attachment"
                        rules={[
                          {
                            required: true,
                            message: "Wajib Isi",
                          },
                        ]}
                      >
                        <Upload
                          listType="picture"
                          accept="image/*"
                          fileList={fileListVehicle}
                          onChange={handleUploadVehicle}
                          beforeUpload={() => false} // Prevent upload
                        >
                          {fileListVehicle.length === 0 && (
                            <Button icon={<PlusOutlined />}>Upload</Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Aktifitas Unit
                            </span>
                          }
                          name="segment_code"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Pilih Aktifitas"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {EquipmentsActivity.map((item, index) => (
                              <Select.Option
                                key={index}
                                value={item.segment_code}
                              >
                                {item.segment_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Kilometer
                            </span>
                          }
                          name="kilometer"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="KM"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Hourmeter
                            </span>
                          }
                          name="hourmeter"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="HM"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Lampiran Meter
                            </span>
                          }
                          name="vehicle_meter_attachment"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Upload
                            listType="picture"
                            accept="image/*"
                            fileList={fileList}
                            onChange={handleUpload}
                            beforeUpload={() => false} // Prevent upload
                          >
                            {fileList.length === 0 && (
                              <Button icon={<PlusOutlined />}>Upload</Button>
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Lampiran KM Meter
                            </span>
                          }
                          name="vehicle_kmeter_attachment"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Upload
                            listType="picture"
                            accept="image/*"
                            fileList={fileListKMmeter}
                            onChange={handleUploadKmMeter}
                            beforeUpload={() => false} // Prevent upload
                          >
                            {fileListKMmeter.length === 0 && (
                              <Button icon={<PlusOutlined />}>Upload</Button>
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Override Otorisasi
                            </span>
                          }
                          name="override"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={handleOverrideChange}
                            defaultValue={overrideOperator}
                          >
                            <Radio value="Operator">Driver / Operator</Radio>
                            <Radio value="Delegation">Delegasi</Radio>
                            <Radio value="Manual" className="mt-2">
                              Langsung Input
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        {overrideOperator === "Operator" ? (
                          <Form.Item
                            label={
                              <span
                                style={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                Nama Supir / Operator
                              </span>
                            }
                            name="operator_id"
                            rules={[
                              {
                                required: true,
                                message: "Wajib Isi",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              onChange={handleChangeOperator}
                              ref={operatorInputRef}
                              clearIcon={false}
                              suffixIcon={
                                Operators.length > 0 ? (
                                  <CheckCircleOutlined
                                    style={{ color: "green", fontSize: 18 }}
                                  />
                                ) : (
                                  <CloseCircleOutlined
                                    style={{ color: "red", fontSize: 18 }}
                                  />
                                )
                              }
                            >
                              {Operators.map((item) => (
                                <Select.Option
                                  key={item.employee_id}
                                  value={item.employee_id}
                                >
                                  {item.fullname} - {item.employee_id}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : overrideOperator === "Manual" ? (
                          <>
                            <Row gutter={20}>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <Form.Item
                                  label={
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ID Karyawan
                                    </span>
                                  }
                                  name="operator_id"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Wajib Isi",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <Form.Item
                                  label={
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ID Simper
                                    </span>
                                  }
                                  name="company_driver_license"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Wajib Isi",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <Form.Item
                                  label={
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Nama Lengkap
                                    </span>
                                  }
                                  name="fullname"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Wajib Isi",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <Form.Item
                                  label={
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Perusahaan
                                    </span>
                                  }
                                  name="company"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Wajib Isi",
                                    },
                                  ]}
                                >
                                  <Select>
                                    <Select.Option value="SUMAGUD SAPTA SINA">
                                      SUMAGUD SAPTA SINA
                                    </Select.Option>
                                    <Select.Option value="CITRA PALU MINERALS">
                                      CITRA PALU MINERALS
                                    </Select.Option>
                                    <Select.Option value="MACMAHON MINNING SERVICE">
                                      MACMAHON MINNING SERVICE
                                    </Select.Option>
                                    <Select.Option value="ADIJAYA KARYA MAKMUR">
                                      ADIJAYA KARYA MAKMUR
                                    </Select.Option>
                                    <Select.Option value="PETRAMAS HARJA SEJAHTERA">
                                      PETRAMAS HARJA SEJAHTERA
                                    </Select.Option>
                                    <Select.Option value="PESONA PRIMA UTAMA">
                                      PESONA PRIMA UTAMA
                                    </Select.Option>
                                    <Select.Option value="KOPERASI MOSINGGANI">
                                      KOPERASI MOSINGGANI
                                    </Select.Option>
                                    <Select.Option value="PARTS SENTRA MANDIRI">
                                      PARTS SENTRA MANDIRI
                                    </Select.Option>
                                    <Select.Option value="Other">
                                      Lain Lain
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <Form.Item
                                  label={
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Nomor Handphone
                                    </span>
                                  }
                                  name="phonenumber"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Wajib Isi",
                                    },
                                  ]}
                                  normalize={(value) => {
                                    // Normalisasi nilai input
                                    if (value.startsWith("0")) {
                                      return value.replace(/^0/, "62");
                                    }
                                    return value;
                                  }}
                                >
                                  <Input placeholder="Contoh: 081234567890" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Form.Item
                            label={
                              <span
                                style={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                Nama Karyawan
                              </span>
                            }
                            name="override_id"
                            rules={[
                              {
                                required: true,
                                message: "Wajib Isi",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              // onChange={handleChangeOperator}
                              // ref={operatorInputRef}
                              clearIcon={false}
                            >
                              {Organization.map((item) => (
                                <Select.Option
                                  key={item.barcode}
                                  value={item.barcode}
                                >
                                  {item.first_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>
                      <Form.Item
                        label={
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Foto Operator
                          </span>
                        }
                        name="attachment_operator"
                        rules={[
                          {
                            required: true,
                            message: "Wajib Isi",
                          },
                        ]}
                      >
                        <Upload
                          listType="picture"
                          accept="image/*"
                          fileList={fileListOperator}
                          onChange={handleUploadOperator}
                          beforeUpload={() => false} // Prevent upload
                        >
                          {fileListOperator.length === 0 && (
                            <Button icon={<PlusOutlined />}>Upload</Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Row>

                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Jenis Bahan Bakar
                            </span>
                          }
                          name="fuel_type"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Radio.Group defaultValue="Solar">
                            <Radio value="Solar">Solar</Radio>
                            <Radio value="Benzine">Bensin</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Jumlah diajukan
                            </span>
                          }
                          name="amount_submitted"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            onChange={handleAmountSubmittedChange}
                            step={0.1}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Jumlah dikeluarkan
                            </span>
                          }
                          name="amount_issued"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Lampiran Flowmeter
                            </span>
                          }
                          name="flow_attachment"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Upload
                            listType="picture"
                            accept="image/*"
                            fileList={fileListMeter}
                            onChange={handleUploadMeter}
                            beforeUpload={() => false} // Prevent upload
                          >
                            {fileListMeter.length === 0 && (
                              <Button icon={<PlusOutlined />}>Upload</Button>
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col>
                        <Form.Item className="mt-3">
                          <Button
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            type="primary"
                            size="large"
                            htmlType="submit"
                            icon={<CheckCircleOutlined />}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Layout.Footer style={{ textAlign: "center", background: "#F9F9F9" }}>
        ©2024 Information Technology
      </Layout.Footer>
    </>
  );
}
export default CreateRequestFuel;
